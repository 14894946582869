.filter_sec {
  background: map-get($theme, card);
  padding: 20px 20px 23px 20px;
  border: 1px solid map-get($theme, borderclr);
  border-radius: 10px;
  margin-top: -79px;
  z-index: 1;
  position: relative;
  @include media-breakpoint-down(lg) {
    margin-top: 30px;
  }
  @include media-breakpoint-down(md) {
    margin: 15px;
  }
  &.editlisting {
    margin: 0px 0px 20px 0px;
  }
  .filter_checked {
    display: none;
    &.filter_checked_true {
      display: flex;
    }
  }
  .pad_mob_change {
    @include media-breakpoint-down(xl) {
      padding: 0px;
    }
  }
  .mob_flex_direc {
    @include media-breakpoint-down(lg) {
      flex-direction: column;
    }
  }
  .pad_mob_change {
    @include media-breakpoint-down(xl) {
      padding: 0;
    }
  }
  .filter_cover {
    padding: 3px;
    position: relative;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
    &.filter_cover_full {
      width: 100%;
    }
    &.drop_checked {
      display: none;
    }
    .filter_wrapper {
      display: flex;
      align-items: center;
      padding: 10px;
      background: map-get($theme, write);
      border: 1px solid #cfcfcf;
      border-radius: 10px;
      i {
        font-size: 20px;
        margin-right: 5px;
        margin-left: 5px;
      }
      .filter_inner {
        width: 100%;
        &.sumomax {
          max-width: 145px;
        }
        p {
          margin-bottom: 0;
          font-size: 12px;
          font-weight: 400;
          font-family: map-get($theme, fontFamily);

          @include media-breakpoint-down(xxl) {
            font-size: 10px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 12px;
          }
        }
        input {
          width: 100%;
          border: none;
          font-size: 14px;
          font-weight: 500;
          padding: 0;
          font-family: map-get($theme, fontFamily);
          outline: none;
          min-height: 32px;
          max-height: 32px;
        }
        .SumoSelect {
          width: 94%;

          .loc_select {
          }
          p {
            padding: 0;
            border: none;
            span {
              font-size: 15px;
              font-weight: bold;
              padding: 0;
            }
            label {
              i {
                display: none;
              }
            }
          }
          .optWrapper {
            .options {
              .opt {
                label {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
  .filter_button {
    button {
      width: 100%;
      max-height: 81px;
      height: 100%;
      font-size: 20px;
      border-radius: 10px;
      @include media-breakpoint-down(xl) {
        margin-top: 10px;
      }
    }
    @include media-breakpoint-down(lg) {
      padding: 0px;
      margin-top: 10px;
    }
  }
  .checkbox_sec {
    margin-top: 10px;
    display: flex;
    align-items: flex-end;
    input {
      width: 26px;
      height: 26px;
      margin-right: 10px;
      margin-left: 10px;
    }
    p {
      margin-bottom: 0px;
      font-size: 16px;
      font-family: map-get($theme, fontFamily);
    }
  }
  .location_details {
    padding-top: 30px;
    border-top: 1px solid #cfcfcf;
    margin-top: 30px;
    min-height: 45vh;
    .inner_loc_details {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;

      padding: 10px 0px;

      i {
        font-size: 25px;
        margin-top: 2px;
        margin-left: 10px;
        margin-right: 10px;
      }
      p {
        font-size: 16px;
        margin-bottom: 0px;
      }
      &:hover,
      &:active,
      &:focus {
        background: white;
      }
    }
  }
}

section {
  &.aboutus {
    margin-top: 110px;
    background: map-get($theme, link);
    padding: 75px 0px;
  }
  &.top_slider {
    margin-top: 50px;
    .owl-carousel {
      margin-top: 50px;
    }
    .swiper-pagination {
      display: none;
    }

    .swiper-button-prev {
      display: none;
    }
    .swiper-button-next {
      display: none;
    }
    .swiper-scrollbar {
      display: none;
    }
  }
  &.second_slider {
    margin-top: 100px;
  }
}

.swiper {
  &.swiperone {
    margin-top: 50px;
  }
  max-height: 100%;

  // .swiper-slide {
  //   display: block;

  //   &.swiper-slide-active {
  //     .car_card {
  //       height: 100%;
  //       background: map-get($theme, bg);
  //       border: 2px solid map-get($theme, link);
  //       // max-height: var(--app-card-height, 80vh);
  //       .car_details {
  //         .ext {
  //           opacity: 1;
  //         }
  //       }
  //     }
  //   }
  //   &.swiper-slide-prev {
  //     display: block;
  //   }
  //   &.swiper-slide-next {
  //     display: block;
  //   }
  // }

  &.second {
    margin: 150px 0px 0px 0px;

    @include media-breakpoint-down(lg) {
      margin: 0px 0px 0px 0px;
    }
    .news {
      margin: 15px;
      .image {
        padding: 0px;
        picture {
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
      .content {
        padding: 0px 10px 0px 0px;
        @include media-breakpoint-down(lg) {
          padding: 0px 0px 0px 0px;
        }
        .inner {
          box-shadow: -1px 0px 7px -2px map-get($theme, borderclr);
          display: flex;
          align-content: center;
          flex-direction: column;
          justify-content: center;
          padding: 22px 33px;
          margin: 1px;
          height: 100%;
          background: map-get($theme, write);

          .head {
            font-family: map-get($theme, fontFamily);
            font-size: 22px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 25px;
            text-align: left;
            @include media-breakpoint-down(xxl) {
              font-size: 18px;
            }
          }
          .date_sec {
            font-family: map-get($theme, fontFamily);
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            word-spacing: 11.6px;
          }
          .description {
            font-family: map-get($theme, fontFamily);
            font-size: 14px;
            font-weight: 400;
            line-height: 25px;
            margin-bottom: 30px;
          }
          .pic_name {
            display: flex;
            align-items: baseline;
            picture {
              margin-right: 8px;
            }
          }
        }
      }
    }
    .swiper-button-prev {
      i {
        color: map-get($theme, text);
        font-size: 26px;
      }
    }
    .swiper-button-next {
      i {
        color: map-get($theme, text);
        font-size: 26px;
      }
    }
  }
}
.swiper-controls {
  position: relative;
  .swiper-button-next {
    top: 0;
    i {
      position: absolute;
      top: -250px;
      right: -104px;
      font-size: 30px;
      color: map-get($theme, text);
    }
  }
  .swiper-button-prev {
    top: 0;
    i {
      position: absolute;
      top: -250px;
      left: -104px;
      font-size: 30px;
      color: map-get($theme, text);
    }
  }
}
.about_sec {
  .about_heading {
    font-family: map-get($theme, fontFamily);
    font-size: 76px;
    color: map-get($theme, text);
    font-weight: 200;
    line-height: 76px;
    margin-top: 25px;
    @include media-breakpoint-down(md) {
      font-size: 48px;
      line-height: 48px;
    }
  }
  .about_subheading {
    font-family: map-get($theme, fontFamily);
    color: map-get($theme, text);
    font-size: 44px;
    font-weight: 700;
    margin-bottom: 20px;
    margin-top: 30px;
  }
  p {
    font-family: map-get($theme, fontFamily);
    color: map-get($theme, text);
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
  }
  picture {
    img {
      width: 100%;
    }
  }
}
.testmonial_sec {
  margin-top: 100px;
  .h3 {
    @include media-breakpoint-down(lg) {
      margin: 15px;
    }
  }
  .testmonial_wapper {
    margin-top: 90px;
    .testmonial_inner {
      border: 1px solid map-get($theme, borderclr);
      border-radius: 10px;
      padding: 50px 90px 25px 25px;
      max-width: 382px;
      margin: auto;
      @include media-breakpoint-down(lg) {
        margin: 0px 0px 10px 0px;
        max-width: 100%;
      }
      @include media-breakpoint-down(md) {
        padding: 25px 25px 25px 25px;
      }
      .content {
        font-family: map-get($theme, fontFamily);
        color: map-get($theme, text);
        font-size: 16px;
        line-height: 28px;
      }
      .author_sec {
        margin-top: 55px;
        display: flex;
        align-items: center;
        picture {
          margin-right: 15px;
        }
        .author_name {
          .name {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            margin-bottom: 6px;
            color: map-get($theme, text);
          }
          .description {
            font-size: 14px;
            line-height: 16px;
            font-weight: 500;
            margin-bottom: 0px;
            color: #a7a7a7;
          }
        }
      }
    }
  }
}
.map_sec {
  margin-top: 150px;

  .loc_title {
    text-align: center;
    font-size: 44px;
    line-height: 66px;
    font-weight: 500;
    position: relative;
  }
  .loc_stitle {
    font-weight: 300;
    text-align: center;
    font-size: 44px;
    line-height: 66px;
    position: relative;
  }
  .map_inner {
    padding: 0px;
    height: 810px;
    position: relative;
    &:after {
      content: " ";
      position: absolute;
      background: url("../images/map_shade.png");
      height: 100%;
      display: block;
      width: 100%;
      left: 0;
      z-index: 200;
      top: 0;
      background-repeat: no-repeat;
      background-size: 100%;
      @include media-breakpoint-down(lg) {
        background-size: 100% 30%;
      }
    }
  }
}
