$info: map-get($color, info);
$dark: map-get($color, dark);
$light: map-get($color, light);
$accent: map-get($color, accent);
$danger: map-get($color, danger);
$success: map-get($color, success);
$warning: map-get($color, warning);
$primary: map-get($color, primary);
$secondary: map-get($color, secondary);

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";

// $enable-caret:                true;
// $enable-rounded:              true;
// $enable-shadows:              false;
// $enable-gradients:            false;
// $enable-transitions:          true;
// $enable-reduced-motion:       true;
// $enable-smooth-scroll:        true;
// $enable-grid-classes:         true;
// $enable-cssgrid:              true;
// $enable-button-pointers:      true;
// $enable-rfs:                  true;
// $enable-validation-icons:     true;
// $enable-negative-margins:     false;
// $enable-deprecation-messages: true;
// $enable-important-utilities:  true;

$font-sizes: (
  1: rem(42px),
  2: rem(30px),
  3: rem(24px),
  4: rem(20px),
  5: rem(18px),
  6: rem(16px),
);

$theme-colors: (
  info: map-get($color, info),
  dark: map-get($color, dark),
  light: map-get($color, light),
  theme: map-get($theme, color),
  accent: map-get($color, accent),
  danger: map-get($color, danger),
  warning: map-get($color, warning),
  success: map-get($color, success),
  primary: map-get($color, primary),
);

$grid-columns: 24;
// $grid-row-columns: 6;
$grid-gutter-width: 30px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  // 1460px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  // 1400px
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

$border-color: map-get($theme, brdr);

$border-radius: map-get($global, border-radius);
$border-radius-sm: map-get($global, border-radius-sm); // 0.2rem;
$border-radius-lg: map-get($global, border-radius-lg); // .3rem;
// $border-radius-pill: 50rem;

$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius-lg;

$input-border-radius: $border-radius;
$input-border-radius-sm: $border-radius-sm;
$input-border-radius-lg: $border-radius-lg;

$navbar-dark-color: map-get($theme, header-text);
$navbar-brand-font-size: 24px;

// $form-feedback-icon-valid: none;
// $form-feedback-icon-invalid: none;

$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

$link-color: map-get($theme, link);

// $line-height-base: 1.5 !default;
// $line-height-sm: 1.25 !default;
// $line-height-lg: 2 !default;

// $aspect-ratios: (
//   "1x1": 100%,
//   "4x3": calc(3 / 4 * 100%),
//   "16x9": calc(9 / 16 * 100%),
//   "21x9": calc(9 / 21 * 100%)
// );

// $colors: (
//   "blue":       $blue,
//   "indigo":     $indigo,
//   "purple":     $purple,
//   "pink":       $pink,
//   "red":        $red,
//   "orange":     $orange,
//   "yellow":     $yellow,
//   "green":      $green,
//   "teal":       $teal,
//   "cyan":       $cyan,
//   "white":      $white,
//   "gray":       $gray-600,
//   "gray-dark":  $gray-800
// );
