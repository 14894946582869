$icomoon-font-family: "icon" !default;
$icomoon-font-path: "fonts" !default;

$i-twitter: "\eea4";
$i-error2: "\eea3";
$i-youtubenew: "\ee9f";
$i-twitternew: "\eea0";
$i-instagramnew: "\eea1";
$i-facebooknew: "\eea2";
$i-calendar: "\ee9e";
$i-carelectric: "\ee99";
$i-carfamily: "\ee9a";
$i-carsuv: "\ee9b";
$i-carcouple: "\ee9c";
$i-cartype: "\ee9d";
$i-more1: "\ee97";
$i-right_arrow: "\e911";
$i-left_arrow: "\ee96";
$i-speedometer: "\e900";
$i-wheel: "\e901";
$i-gas: "\e902";
$i-door: "\e903";
$i-bag: "\e904";
$i-seat: "\e905";
$i-error: "\e906";
$i-error_outline: "\e907";
$i-warning: "\e908";
$i-add_alert: "\e909";
$i-notification_important: "\e90a";
$i-album: "\e90b";
$i-av_timer: "\e90c";
$i-closed_caption: "\e90d";
$i-equalizer: "\e90e";
$i-explicit: "\e90f";
$i-fast_forward: "\e910";
$i-fast_rewind: "\ee98";
$i-games: "\e912";
$i-hearing: "\e913";
$i-high_quality: "\e914";
$i-loop: "\e915";
$i-mic: "\e916";
$i-mic_none: "\e917";
$i-mic_off: "\e918";
$i-movie: "\e919";
$i-library_add: "\e91a";
$i-library_books: "\e91b";
$i-library_music: "\e91c";
$i-new_releases: "\e91d";
$i-not_interested: "\e91e";
$i-pause: "\e91f";
$i-pause_circle_filled: "\e920";
$i-pause_circle_outline: "\e921";
$i-play_arrow: "\e922";
$i-play_circle_filled: "\e923";
$i-play_circle_outline: "\e924";
$i-playlist_add: "\e925";
$i-queue_music: "\e926";
$i-radio: "\e927";
$i-recent_actors: "\e928";
$i-repeat: "\e929";
$i-repeat_one: "\e92a";
$i-replay: "\e92b";
$i-shuffle: "\e92c";
$i-skip_next: "\e92d";
$i-skip_previous: "\e92e";
$i-snooze: "\e92f";
$i-stop: "\e930";
$i-subtitles: "\e931";
$i-surround_sound: "\e932";
$i-video_library: "\e933";
$i-videocam: "\e934";
$i-videocam_off: "\e935";
$i-volume_down: "\e936";
$i-volume_mute: "\e937";
$i-volume_off: "\e938";
$i-volume_up: "\e939";
$i-web: "\e93a";
$i-hd: "\e93b";
$i-sort_by_alpha: "\e93c";
$i-airplay: "\e93d";
$i-forward_10: "\e93e";
$i-forward_30: "\e93f";
$i-forward_5: "\e940";
$i-replay_10: "\e941";
$i-replay_30: "\e942";
$i-replay_5: "\e943";
$i-add_to_queue: "\e944";
$i-fiber_dvr: "\e945";
$i-fiber_new: "\e946";
$i-playlist_play: "\e947";
$i-art_track: "\e948";
$i-fiber_manual_record: "\e949";
$i-fiber_smart_record: "\e94a";
$i-music_video: "\e94b";
$i-subscriptions: "\e94c";
$i-playlist_add_check: "\e94d";
$i-queue_play_next: "\e94e";
$i-remove_from_queue: "\e94f";
$i-slow_motion_video: "\e950";
$i-web_asset: "\e951";
$i-fiber_pin: "\e952";
$i-branding_watermark: "\e953";
$i-call_to_action: "\e954";
$i-featured_play_list: "\e955";
$i-featured_video: "\e956";
$i-note: "\e957";
$i-video_call: "\e958";
$i-video_label: "\e959";
$i-4k: "\e95a";
$i-missed_video_call: "\e95b";
$i-control_camera: "\e95c";
$i-business: "\e95d";
$i-call: "\e95e";
$i-call_end: "\e95f";
$i-call_made: "\e960";
$i-call_merge: "\e961";
$i-call_missed: "\e962";
$i-call_received: "\e963";
$i-call_split: "\e964";
$i-chat: "\e965";
$i-clear_all: "\e966";
$i-comment: "\e967";
$i-contacts: "\e968";
$i-dialer_sip: "\e969";
$i-dialpad: "\e96a";
$i-email: "\e96b";
$i-forum: "\e96c";
$i-import_export: "\e96d";
$i-invert_colors_off: "\e96e";
$i-live_help: "\e96f";
$i-location_off: "\e970";
$i-location_on: "\e971";
$i-message: "\e972";
$i-chat_bubble: "\e973";
$i-chat_bubble_outline: "\e974";
$i-no_sim: "\e975";
$i-phone: "\e976";
$i-portable_wifi_off: "\e977";
$i-contact_phone: "\e978";
$i-contact_mail: "\e979";
$i-ring_volume: "\e97a";
$i-speaker_phone: "\e97b";
$i-stay_current_landscape: "\e97c";
$i-stay_current_portrait: "\e97d";
$i-swap_calls: "\e97e";
$i-textsms: "\e97f";
$i-voicemail: "\e980";
$i-vpn_key: "\e981";
$i-phonelink_erase: "\e982";
$i-phonelink_lock: "\e983";
$i-phonelink_ring: "\e984";
$i-phonelink_setup: "\e985";
$i-present_to_all: "\e986";
$i-import_contacts: "\e987";
$i-mail_outline: "\e988";
$i-screen_share: "\e989";
$i-stop_screen_share: "\e98a";
$i-call_missed_outgoing: "\e98b";
$i-rss_feed: "\e98c";
$i-alternate_email: "\e98d";
$i-mobile_screen_share: "\e98e";
$i-add_call: "\e98f";
$i-cancel_presentation: "\e990";
$i-pause_presentation: "\e991";
$i-unsubscribe: "\e992";
$i-cell_wifi: "\e993";
$i-sentiment_satisfied_alt: "\e994";
$i-list_alt: "\e995";
$i-domain_disabled: "\e996";
$i-lightbulb: "\e997";
$i-add: "\e998";
$i-add_box: "\e999";
$i-add_circle: "\e99a";
$i-add_circle_outline: "\e99b";
$i-archive: "\e99c";
$i-backspace: "\e99d";
$i-block: "\e99e";
$i-clear: "\e99f";
$i-content_copy: "\e9a0";
$i-content_cut: "\e9a1";
$i-content_paste: "\e9a2";
$i-create: "\e9a3";
$i-drafts: "\e9a4";
$i-filter_list: "\e9a5";
$i-flag: "\e9a6";
$i-forward: "\e9a7";
$i-gesture: "\e9a8";
$i-inbox: "\e9a9";
$i-link: "\e9aa";
$i-redo: "\e9ab";
$i-remove: "\e9ac";
$i-remove_circle: "\e9ad";
$i-remove_circle_outline: "\e9ae";
$i-reply: "\e9af";
$i-reply_all: "\e9b0";
$i-report: "\e9b1";
$i-save: "\e9b2";
$i-select_all: "\e9b3";
$i-send: "\e9b4";
$i-sort: "\e9b5";
$i-text_format: "\e9b6";
$i-undo: "\e9b7";
$i-font_download: "\e9b8";
$i-move_to_inbox: "\e9b9";
$i-unarchive: "\e9ba";
$i-next_week: "\e9bb";
$i-weekend: "\e9bc";
$i-delete_sweep: "\e9bd";
$i-low_priority: "\e9be";
$i-outlined_flag: "\e9bf";
$i-link_off: "\e9c0";
$i-report_off: "\e9c1";
$i-save_alt: "\e9c2";
$i-ballot: "\e9c3";
$i-file_copy: "\e9c4";
$i-how_to_reg: "\e9c5";
$i-how_to_vote: "\e9c6";
$i-waves: "\e9c7";
$i-where_to_vote: "\e9c8";
$i-add_link: "\e9c9";
$i-inventory: "\e9ca";
$i-access_alarm: "\e9cb";
$i-access_alarms: "\e9cc";
$i-access_time: "\e9cd";
$i-add_alarm: "\e9ce";
$i-airplanemode_off: "\e9cf";
$i-airplanemode_on: "\e9d0";
$i-battery_alert: "\e9d1";
$i-battery_charging_full: "\e9d2";
$i-battery_full: "\e9d3";
$i-battery_unknown: "\e9d4";
$i-bluetooth: "\e9d5";
$i-bluetooth_connected: "\e9d6";
$i-bluetooth_disabled: "\e9d7";
$i-bluetooth_searching: "\e9d8";
$i-brightness_auto: "\e9d9";
$i-brightness_high: "\e9da";
$i-brightness_low: "\e9db";
$i-brightness_medium: "\e9dc";
$i-data_usage: "\e9dd";
$i-developer_mode: "\e9de";
$i-devices: "\e9df";
$i-dvr: "\e9e0";
$i-gps_fixed: "\e9e1";
$i-gps_not_fixed: "\e9e2";
$i-gps_off: "\e9e3";
$i-graphic_eq: "\e9e4";
$i-network_cell: "\e9e5";
$i-network_wifi: "\e9e6";
$i-nfc: "\e9e7";
$i-now_wallpaper: "\e9e8";
$i-now_widgets: "\e9e9";
$i-screen_lock_landscape: "\e9ea";
$i-screen_lock_portrait: "\e9eb";
$i-screen_lock_rotation: "\e9ec";
$i-screen_rotation: "\e9ed";
$i-sd_storage: "\e9ee";
$i-settings_system_daydream: "\e9ef";
$i-signal_cellular_4_bar: "\e9f0";
$i-signal_cellular_connected_no_internet_4_bar: "\e9f1";
$i-signal_cellular_null: "\e9f2";
$i-signal_cellular_off: "\e9f3";
$i-signal_wifi_4_bar: "\e9f4";
$i-signal_wifi_4_bar_lock: "\e9f5";
$i-signal_wifi_off: "\e9f6";
$i-storage: "\e9f7";
$i-usb: "\e9f8";
$i-wifi_lock: "\e9f9";
$i-wifi_tethering: "\e9fa";
$i-add_to_home_screen: "\e9fb";
$i-device_thermostat: "\e9fc";
$i-mobile_friendly: "\e9fd";
$i-mobile_off: "\e9fe";
$i-signal_cellular_alt: "\e9ff";
$i-attach_file: "\ea00";
$i-attach_money: "\ea01";
$i-border_all: "\ea02";
$i-border_bottom: "\ea03";
$i-border_clear: "\ea04";
$i-border_color: "\ea05";
$i-border_horizontal: "\ea06";
$i-border_inner: "\ea07";
$i-border_left: "\ea08";
$i-border_outer: "\ea09";
$i-border_right: "\ea0a";
$i-border_style: "\ea0b";
$i-border_top: "\ea0c";
$i-border_vertical: "\ea0d";
$i-format_align_center: "\ea0e";
$i-format_align_justify: "\ea0f";
$i-format_align_left: "\ea10";
$i-format_align_right: "\ea11";
$i-format_bold: "\ea12";
$i-format_clear: "\ea13";
$i-format_color_fill: "\ea14";
$i-format_color_reset: "\ea15";
$i-format_color_text: "\ea16";
$i-format_indent_decrease: "\ea17";
$i-format_indent_increase: "\ea18";
$i-format_italic: "\ea19";
$i-format_line_spacing: "\ea1a";
$i-format_list_bulleted: "\ea1b";
$i-format_list_numbered: "\ea1c";
$i-format_paint: "\ea1d";
$i-format_quote: "\ea1e";
$i-format_size: "\ea1f";
$i-format_strikethrough: "\ea20";
$i-format_textdirection_l_to_r: "\ea21";
$i-format_textdirection_r_to_l: "\ea22";
$i-format_underlined: "\ea23";
$i-functions: "\ea24";
$i-insert_chart: "\ea25";
$i-insert_comment: "\ea26";
$i-insert_drive_file: "\ea27";
$i-insert_emoticon: "\ea28";
$i-insert_invitation: "\ea29";
$i-insert_photo: "\ea2a";
$i-mode_comment: "\ea2b";
$i-publish: "\ea2c";
$i-space_bar: "\ea2d";
$i-strikethrough_s: "\ea2e";
$i-vertical_align_bottom: "\ea2f";
$i-vertical_align_center: "\ea30";
$i-vertical_align_top: "\ea31";
$i-wrap_text: "\ea32";
$i-money_off: "\ea33";
$i-drag_handle: "\ea34";
$i-format_shapes: "\ea35";
$i-highlight: "\ea36";
$i-linear_scale: "\ea37";
$i-short_text: "\ea38";
$i-text_fields: "\ea39";
$i-monetization_on: "\ea3a";
$i-title: "\ea3b";
$i-table_chart: "\ea3c";
$i-add_comment: "\ea3d";
$i-format_list_numbered_rtl: "\ea3e";
$i-scatter_plot: "\ea3f";
$i-score: "\ea40";
$i-insert_chart_outlined: "\ea41";
$i-bar_chart: "\ea42";
$i-notes: "\ea43";
$i-attachment: "\ea44";
$i-cloud: "\ea45";
$i-cloud_circle: "\ea46";
$i-cloud_done: "\ea47";
$i-cloud_download: "\ea48";
$i-cloud_off: "\ea49";
$i-cloud_queue: "\ea4a";
$i-cloud_upload: "\ea4b";
$i-file_download: "\ea4c";
$i-file_upload: "\ea4d";
$i-folder: "\ea4e";
$i-folder_open: "\ea4f";
$i-folder_shared: "\ea50";
$i-create_new_folder: "\ea51";
$i-cast: "\ea52";
$i-cast_connected: "\ea53";
$i-computer: "\ea54";
$i-desktop_mac: "\ea55";
$i-desktop_windows: "\ea56";
$i-developer_board: "\ea57";
$i-dock: "\ea58";
$i-headset: "\ea59";
$i-headset_mic: "\ea5a";
$i-keyboard: "\ea5b";
$i-keyboard_arrow_down: "\ea5c";
$i-keyboard_arrow_left: "\ea5d";
$i-keyboard_arrow_right: "\ea5e";
$i-keyboard_arrow_up: "\ea5f";
$i-keyboard_backspace: "\ea60";
$i-keyboard_capslock: "\ea61";
$i-keyboard_hide: "\ea62";
$i-keyboard_return: "\ea63";
$i-keyboard_tab: "\ea64";
$i-keyboard_voice: "\ea65";
$i-laptop_chromebook: "\ea66";
$i-laptop_mac: "\ea67";
$i-laptop_windows: "\ea68";
$i-memory: "\ea69";
$i-mouse: "\ea6a";
$i-phone_android: "\ea6b";
$i-phone_iphone: "\ea6c";
$i-phonelink_off: "\ea6d";
$i-router: "\ea6e";
$i-scanner: "\ea6f";
$i-security: "\ea70";
$i-sim_card: "\ea71";
$i-speaker: "\ea72";
$i-speaker_group: "\ea73";
$i-tablet: "\ea74";
$i-tablet_android: "\ea75";
$i-tablet_mac: "\ea76";
$i-toys: "\ea77";
$i-tv: "\ea78";
$i-watch: "\ea79";
$i-device_hub: "\ea7a";
$i-power_input: "\ea7b";
$i-devices_other: "\ea7c";
$i-videogame_asset: "\ea7d";
$i-device_unknown: "\ea7e";
$i-headset_off: "\ea7f";
$i-adjust: "\ea80";
$i-assistant: "\ea81";
$i-audiotrack: "\ea82";
$i-blur_circular: "\ea83";
$i-blur_linear: "\ea84";
$i-blur_off: "\ea85";
$i-blur_on: "\ea86";
$i-brightness_1: "\ea87";
$i-brightness_2: "\ea88";
$i-brightness_3: "\ea89";
$i-brightness_4: "\ea8a";
$i-broken_image: "\ea8b";
$i-brush: "\ea8c";
$i-camera: "\ea8d";
$i-camera_alt: "\ea8e";
$i-camera_front: "\ea8f";
$i-camera_rear: "\ea90";
$i-camera_roll: "\ea91";
$i-center_focus_strong: "\ea92";
$i-center_focus_weak: "\ea93";
$i-collections: "\ea94";
$i-color_lens: "\ea95";
$i-colorize: "\ea96";
$i-compare: "\ea97";
$i-control_point_duplicate: "\ea98";
$i-crop_16_9: "\ea99";
$i-crop_3_2: "\ea9a";
$i-crop: "\ea9b";
$i-crop_5_4: "\ea9c";
$i-crop_7_5: "\ea9d";
$i-crop_din: "\ea9e";
$i-crop_free: "\ea9f";
$i-crop_original: "\eaa0";
$i-crop_portrait: "\eaa1";
$i-crop_square: "\eaa2";
$i-dehaze: "\eaa3";
$i-details: "\eaa4";
$i-exposure: "\eaa5";
$i-exposure_neg_1: "\eaa6";
$i-exposure_neg_2: "\eaa7";
$i-exposure_plus_1: "\eaa8";
$i-exposure_plus_2: "\eaa9";
$i-exposure_zero: "\eaaa";
$i-filter_1: "\eaab";
$i-filter_2: "\eaac";
$i-filter_3: "\eaad";
$i-filter: "\eaae";
$i-filter_4: "\eaaf";
$i-filter_5: "\eab0";
$i-filter_6: "\eab1";
$i-filter_7: "\eab2";
$i-filter_8: "\eab3";
$i-filter_9: "\eab4";
$i-filter_9_plus: "\eab5";
$i-filter_b_and_w: "\eab6";
$i-filter_center_focus: "\eab7";
$i-filter_drama: "\eab8";
$i-filter_frames: "\eab9";
$i-filter_hdr: "\eaba";
$i-filter_none: "\eabb";
$i-filter_tilt_shift: "\eabc";
$i-filter_vintage: "\eabd";
$i-flare: "\eabe";
$i-flash_auto: "\eabf";
$i-flash_off: "\eac0";
$i-flash_on: "\eac1";
$i-flip: "\eac2";
$i-gradient: "\eac3";
$i-grain: "\eac4";
$i-grid_off: "\eac5";
$i-grid_on: "\eac6";
$i-hdr_off: "\eac7";
$i-hdr_on: "\eac8";
$i-hdr_strong: "\eac9";
$i-hdr_weak: "\eaca";
$i-healing: "\eacb";
$i-image_aspect_ratio: "\eacc";
$i-iso: "\eacd";
$i-leak_add: "\eace";
$i-leak_remove: "\eacf";
$i-lens: "\ead0";
$i-looks_3: "\ead1";
$i-looks: "\ead2";
$i-looks_4: "\ead3";
$i-looks_5: "\ead4";
$i-looks_6: "\ead5";
$i-looks_one: "\ead6";
$i-looks_two: "\ead7";
$i-loupe: "\ead8";
$i-monochrome_photos: "\ead9";
$i-music_note: "\eada";
$i-nature: "\eadb";
$i-nature_people: "\eadc";
$i-navigate_before: "\eadd";
$i-navigate_next: "\eade";
$i-panorama: "\eadf";
$i-radio_button_unchecked: "\eae0";
$i-panorama_horizontal: "\eae1";
$i-panorama_vertical: "\eae2";
$i-panorama_wide_angle: "\eae3";
$i-photo_album: "\eae4";
$i-picture_as_pdf: "\eae5";
$i-portrait: "\eae6";
$i-remove_red_eye: "\eae7";
$i-rotate_90_degrees_ccw: "\eae8";
$i-rotate_left: "\eae9";
$i-rotate_right: "\eaea";
$i-slideshow: "\eaeb";
$i-straighten: "\eaec";
$i-style: "\eaed";
$i-switch_camera: "\eaee";
$i-switch_video: "\eaef";
$i-texture: "\eaf0";
$i-timelapse: "\eaf1";
$i-timer_10: "\eaf2";
$i-timer_3: "\eaf3";
$i-timer: "\eaf4";
$i-timer_off: "\eaf5";
$i-tonality: "\eaf6";
$i-transform: "\eaf7";
$i-tune: "\eaf8";
$i-view_comfy: "\eaf9";
$i-view_compact: "\eafa";
$i-wb_auto: "\eafb";
$i-wb_cloudy: "\eafc";
$i-wb_incandescent: "\eafd";
$i-wb_sunny: "\eafe";
$i-collections_bookmark: "\eaff";
$i-photo_size_select_actual: "\eb00";
$i-photo_size_select_large: "\eb01";
$i-photo_size_select_small: "\eb02";
$i-vignette: "\eb03";
$i-wb_iridescent: "\eb04";
$i-crop_rotate: "\eb05";
$i-linked_camera: "\eb06";
$i-add_a_photo: "\eb07";
$i-movie_filter: "\eb08";
$i-photo_filter: "\eb09";
$i-burst_mode: "\eb0a";
$i-shutter_speed: "\eb0b";
$i-add_photo_alternate: "\eb0c";
$i-image_search: "\eb0d";
$i-music_off: "\eb0e";
$i-beenhere: "\eb0f";
$i-directions: "\eb10";
$i-directions_bike: "\eb11";
$i-directions_bus: "\eb12";
$i-directions_car: "\eb13";
$i-directions_ferry: "\eb14";
$i-directions_subway: "\eb15";
$i-directions_train: "\eb16";
$i-directions_walk: "\eb17";
$i-hotel: "\eb18";
$i-layers: "\eb19";
$i-layers_clear: "\eb1a";
$i-local_atm: "\eb1b";
$i-local_attraction: "\eb1c";
$i-local_bar: "\eb1d";
$i-local_cafe: "\eb1e";
$i-local_car_wash: "\eb1f";
$i-local_convenience_store: "\eb20";
$i-local_drink: "\eb21";
$i-local_florist: "\eb22";
$i-local_gas_station: "\eb23";
$i-local_grocery_store: "\eb24";
$i-local_hospital: "\eb25";
$i-local_laundry_service: "\eb26";
$i-local_library: "\eb27";
$i-local_mall: "\eb28";
$i-local_movies: "\eb29";
$i-local_offer: "\eb2a";
$i-local_parking: "\eb2b";
$i-local_pharmacy: "\eb2c";
$i-local_pizza: "\eb2d";
$i-local_printshop: "\eb2e";
$i-local_restaurant: "\eb2f";
$i-local_shipping: "\eb30";
$i-local_taxi: "\eb31";
$i-location_history: "\eb32";
$i-map: "\eb33";
$i-navigation: "\eb34";
$i-pin_drop: "\eb35";
$i-rate_review: "\eb36";
$i-satellite: "\eb37";
$i-store_mall_directory: "\eb38";
$i-traffic: "\eb39";
$i-directions_run: "\eb3a";
$i-add_location: "\eb3b";
$i-edit_location: "\eb3c";
$i-near_me: "\eb3d";
$i-person_pin_circle: "\eb3e";
$i-zoom_out_map: "\eb3f";
$i-restaurant: "\eb40";
$i-ev_station: "\eb41";
$i-streetview: "\eb42";
$i-subway: "\eb43";
$i-train: "\eb44";
$i-tram: "\eb45";
$i-transfer_within_a_station: "\eb46";
$i-atm: "\eb47";
$i-category: "\eb48";
$i-not_listed_location: "\eb49";
$i-departure_board: "\eb4a";
$i-360: "\eb4b";
$i-edit_attributes: "\eb4c";
$i-transit_enterexit: "\eb4d";
$i-fastfood: "\eb4e";
$i-trip_origin: "\eb4f";
$i-compass_calibration: "\eb50";
$i-money: "\eb51";
$i-apps: "\eb52";
$i-arrow_back: "\eb53";
$i-arrow_drop_down: "\eb54";
$i-arrow_drop_down_circle: "\eb55";
$i-arrow_drop_up: "\eb56";
$i-arrow_forward: "\eb57";
$i-cancel: "\eb58";
$i-check: "\eb59";
$i-expand_less: "\eb5a";
$i-expand_more: "\eb5b";
$i-fullscreen: "\eb5c";
$i-fullscreen_exit: "\eb5d";
$i-menu: "\eb5e";
$i-keyboard_control: "\eb5f";
$i-more_vert: "\eb60";
$i-refresh: "\eb61";
$i-unfold_less: "\eb62";
$i-unfold_more: "\eb63";
$i-arrow_upward: "\eb64";
$i-subdirectory_arrow_left: "\eb65";
$i-subdirectory_arrow_right: "\eb66";
$i-arrow_downward: "\eb67";
$i-first_page: "\eb68";
$i-last_page: "\eb69";
$i-arrow_left: "\eb6a";
$i-arrow_right: "\eb6b";
$i-arrow_back_ios: "\eb6c";
$i-arrow_forward_ios: "\eb6d";
$i-adb: "\eb6e";
$i-disc_full: "\eb6f";
$i-do_not_disturb_alt: "\eb70";
$i-drive_eta: "\eb71";
$i-event_available: "\eb72";
$i-event_busy: "\eb73";
$i-event_note: "\eb74";
$i-folder_special: "\eb75";
$i-mms: "\eb76";
$i-more: "\eb77";
$i-network_locked: "\eb78";
$i-phone_bluetooth_speaker: "\eb79";
$i-phone_forwarded: "\eb7a";
$i-phone_in_talk: "\eb7b";
$i-phone_locked: "\eb7c";
$i-phone_missed: "\eb7d";
$i-phone_paused: "\eb7e";
$i-sim_card_alert: "\eb7f";
$i-sms_failed: "\eb80";
$i-sync_disabled: "\eb81";
$i-sync_problem: "\eb82";
$i-system_update: "\eb83";
$i-tap_and_play: "\eb84";
$i-vibration: "\eb85";
$i-voice_chat: "\eb86";
$i-vpn_lock: "\eb87";
$i-airline_seat_flat: "\eb88";
$i-airline_seat_flat_angled: "\eb89";
$i-airline_seat_individual_suite: "\eb8a";
$i-airline_seat_legroom_extra: "\eb8b";
$i-airline_seat_legroom_normal: "\eb8c";
$i-airline_seat_legroom_reduced: "\eb8d";
$i-airline_seat_recline_extra: "\eb8e";
$i-airline_seat_recline_normal: "\eb8f";
$i-confirmation_number: "\eb90";
$i-live_tv: "\eb91";
$i-ondemand_video: "\eb92";
$i-personal_video: "\eb93";
$i-power: "\eb94";
$i-wc: "\eb95";
$i-wifi: "\eb96";
$i-enhanced_encryption: "\eb97";
$i-network_check: "\eb98";
$i-no_encryption: "\eb99";
$i-rv_hookup: "\eb9a";
$i-do_not_disturb_off: "\eb9b";
$i-priority_high: "\eb9c";
$i-power_off: "\eb9d";
$i-tv_off: "\eb9e";
$i-wifi_off: "\eb9f";
$i-phone_callback: "\eba0";
$i-pie_chart: "\eba1";
$i-pie_chart_outlined: "\eba2";
$i-bubble_chart: "\eba3";
$i-multiline_chart: "\eba4";
$i-show_chart: "\eba5";
$i-cake: "\eba6";
$i-group: "\eba7";
$i-group_add: "\eba8";
$i-location_city: "\eba9";
$i-mood_bad: "\ebaa";
$i-notifications: "\ebab";
$i-notifications_none: "\ebac";
$i-notifications_off: "\ebad";
$i-notifications_on: "\ebae";
$i-notifications_paused: "\ebaf";
$i-pages: "\ebb0";
$i-party_mode: "\ebb1";
$i-people_outline: "\ebb2";
$i-person: "\ebb3";
$i-person_add: "\ebb4";
$i-person_outline: "\ebb5";
$i-plus_one: "\ebb6";
$i-public: "\ebb7";
$i-school: "\ebb8";
$i-share: "\ebb9";
$i-whatshot: "\ebba";
$i-sentiment_dissatisfied: "\ebbb";
$i-sentiment_neutral: "\ebbc";
$i-sentiment_satisfied: "\ebbd";
$i-sentiment_very_dissatisfied: "\ebbe";
$i-sentiment_very_satisfied: "\ebbf";
$i-thumb_down_alt: "\ebc0";
$i-thumb_up_alt: "\ebc1";
$i-check_box: "\ebc2";
$i-check_box_outline_blank: "\ebc3";
$i-radio_button_on: "\ebc4";
$i-star: "\ebc5";
$i-star_half: "\ebc6";
$i-star_outline: "\ebc7";
$i-3d_rotation: "\ebc8";
$i-accessibility: "\ebc9";
$i-account_balance: "\ebca";
$i-account_balance_wallet: "\ebcb";
$i-account_box: "\ebcc";
$i-account_circle: "\ebcd";
$i-add_shopping_cart: "\ebce";
$i-alarm_off: "\ebcf";
$i-alarm_on: "\ebd0";
$i-android: "\ebd1";
$i-announcement: "\ebd2";
$i-aspect_ratio: "\ebd3";
$i-assignment: "\ebd4";
$i-assignment_ind: "\ebd5";
$i-assignment_late: "\ebd6";
$i-assignment_return: "\ebd7";
$i-assignment_returned: "\ebd8";
$i-assignment_turned_in: "\ebd9";
$i-autorenew: "\ebda";
$i-book: "\ebdb";
$i-bookmark: "\ebdc";
$i-bookmark_outline: "\ebdd";
$i-bug_report: "\ebde";
$i-build: "\ebdf";
$i-cached: "\ebe0";
$i-change_history: "\ebe1";
$i-check_circle: "\ebe2";
$i-chrome_reader_mode: "\ebe3";
$i-code: "\ebe4";
$i-credit_card: "\ebe5";
$i-dashboard: "\ebe6";
$i-delete: "\ebe7";
$i-description: "\ebe8";
$i-dns: "\ebe9";
$i-done: "\ebea";
$i-done_all: "\ebeb";
$i-exit_to_app: "\ebec";
$i-explore: "\ebed";
$i-extension: "\ebee";
$i-face: "\ebef";
$i-favorite: "\ebf0";
$i-favorite_outline: "\ebf1";
$i-find_in_page: "\ebf2";
$i-find_replace: "\ebf3";
$i-flip_to_back: "\ebf4";
$i-flip_to_front: "\ebf5";
$i-group_work: "\ebf6";
$i-help: "\ebf7";
$i-highlight_remove: "\ebf8";
$i-history: "\ebf9";
$i-home: "\ebfa";
$i-hourglass_empty: "\ebfb";
$i-hourglass_full: "\ebfc";
$i-https: "\ebfd";
$i-info: "\ebfe";
$i-info_outline: "\ebff";
$i-input: "\ec00";
$i-invert_colors_on: "\ec01";
$i-label: "\ec02";
$i-label_outline: "\ec03";
$i-language: "\ec04";
$i-launch: "\ec05";
$i-list: "\ec06";
$i-lock_open: "\ec07";
$i-lock_outline: "\ec08";
$i-loyalty: "\ec09";
$i-markunread_mailbox: "\ec0a";
$i-note_add: "\ec0b";
$i-open_in_browser: "\ec0c";
$i-open_with: "\ec0d";
$i-pageview: "\ec0e";
$i-perm_camera_mic: "\ec0f";
$i-perm_contact_calendar: "\ec10";
$i-perm_data_setting: "\ec11";
$i-perm_device_information: "\ec12";
$i-perm_media: "\ec13";
$i-perm_phone_msg: "\ec14";
$i-perm_scan_wifi: "\ec15";
$i-picture_in_picture: "\ec16";
$i-polymer: "\ec17";
$i-power_settings_new: "\ec18";
$i-receipt: "\ec19";
$i-redeem: "\ec1a";
$i-search: "\ec1b";
$i-settings: "\ec1c";
$i-settings_applications: "\ec1d";
$i-settings_backup_restore: "\ec1e";
$i-settings_bluetooth: "\ec1f";
$i-settings_cell: "\ec20";
$i-settings_display: "\ec21";
$i-settings_ethernet: "\ec22";
$i-settings_input_antenna: "\ec23";
$i-settings_input_component: "\ec24";
$i-settings_input_hdmi: "\ec25";
$i-settings_input_svideo: "\ec26";
$i-settings_overscan: "\ec27";
$i-settings_phone: "\ec28";
$i-settings_power: "\ec29";
$i-settings_remote: "\ec2a";
$i-settings_voice: "\ec2b";
$i-shop: "\ec2c";
$i-shop_two: "\ec2d";
$i-shopping_basket: "\ec2e";
$i-speaker_notes: "\ec2f";
$i-spellcheck: "\ec30";
$i-stars: "\ec31";
$i-subject: "\ec32";
$i-supervisor_account: "\ec33";
$i-swap_horiz: "\ec34";
$i-swap_vert: "\ec35";
$i-swap_vertical_circle: "\ec36";
$i-system_update_tv: "\ec37";
$i-tab: "\ec38";
$i-tab_unselected: "\ec39";
$i-thumb_down: "\ec3a";
$i-thumb_up: "\ec3b";
$i-thumbs_up_down: "\ec3c";
$i-toc: "\ec3d";
$i-today: "\ec3e";
$i-toll: "\ec3f";
$i-track_changes: "\ec40";
$i-translate: "\ec41";
$i-trending_down: "\ec42";
$i-trending_neutral: "\ec43";
$i-trending_up: "\ec44";
$i-verified_user: "\ec45";
$i-view_agenda: "\ec46";
$i-view_array: "\ec47";
$i-view_carousel: "\ec48";
$i-view_column: "\ec49";
$i-view_day: "\ec4a";
$i-view_headline: "\ec4b";
$i-view_list: "\ec4c";
$i-view_module: "\ec4d";
$i-view_quilt: "\ec4e";
$i-view_stream: "\ec4f";
$i-view_week: "\ec50";
$i-visibility_off: "\ec51";
$i-card_membership: "\ec52";
$i-card_travel: "\ec53";
$i-work: "\ec54";
$i-youtube_searched_for: "\ec55";
$i-eject: "\ec56";
$i-camera_enhance: "\ec57";
$i-help_outline: "\ec58";
$i-reorder: "\ec59";
$i-zoom_in: "\ec5a";
$i-zoom_out: "\ec5b";
$i-http: "\ec5c";
$i-event_seat: "\ec5d";
$i-flight_land: "\ec5e";
$i-flight_takeoff: "\ec5f";
$i-play_for_work: "\ec60";
$i-gif: "\ec61";
$i-indeterminate_check_box: "\ec62";
$i-offline_pin: "\ec63";
$i-all_out: "\ec64";
$i-copyright: "\ec65";
$i-fingerprint: "\ec66";
$i-gavel: "\ec67";
$i-lightbulb_outline: "\ec68";
$i-picture_in_picture_alt: "\ec69";
$i-important_devices: "\ec6a";
$i-touch_app: "\ec6b";
$i-accessible: "\ec6c";
$i-compare_arrows: "\ec6d";
$i-date_range: "\ec6e";
$i-donut_large: "\ec6f";
$i-donut_small: "\ec70";
$i-line_style: "\ec71";
$i-line_weight: "\ec72";
$i-motorcycle: "\ec73";
$i-opacity: "\ec74";
$i-pets: "\ec75";
$i-pregnant_woman: "\ec76";
$i-record_voice_over: "\ec77";
$i-rounded_corner: "\ec78";
$i-rowing: "\ec79";
$i-timeline: "\ec7a";
$i-update: "\ec7b";
$i-watch_later: "\ec7c";
$i-pan_tool: "\ec7d";
$i-euro_symbol: "\ec7e";
$i-g_translate: "\ec7f";
$i-remove_shopping_cart: "\ec80";
$i-restore_page: "\ec81";
$i-speaker_notes_off: "\ec82";
$i-delete_forever: "\ec83";
$i-accessibility_new: "\ec84";
$i-check_circle_outline: "\ec85";
$i-delete_outline: "\ec86";
$i-done_outline: "\ec87";
$i-maximize: "\ec88";
$i-minimize: "\ec89";
$i-offline_bolt: "\ec8a";
$i-swap_horizontal_circle: "\ec8b";
$i-accessible_forward: "\ec8c";
$i-calendar_today: "\ec8d";
$i-calendar_view_day: "\ec8e";
$i-label_important: "\ec8f";
$i-restore_from_trash: "\ec90";
$i-supervised_user_circle: "\ec91";
$i-text_rotate_up: "\ec92";
$i-text_rotate_vertical: "\ec93";
$i-text_rotation_angledown: "\ec94";
$i-text_rotation_angleup: "\ec95";
$i-text_rotation_down: "\ec96";
$i-text_rotation_none: "\ec97";
$i-commute: "\ec98";
$i-arrow_right_alt: "\ec99";
$i-work_off: "\ec9a";
$i-work_outline: "\ec9b";
$i-drag_indicator: "\ec9c";
$i-horizontal_split: "\ec9d";
$i-label_important_outline: "\ec9e";
$i-vertical_split: "\ec9f";
$i-voice_over_off: "\eca0";
$i-segment: "\eca1";
$i-contact_support: "\eca2";
$i-compress: "\eca3";
$i-filter_list_alt: "\eca4";
$i-expand: "\eca5";
$i-edit_off: "\eca6";
$i-10k: "\eca7";
$i-10mp: "\eca8";
$i-11mp: "\eca9";
$i-12mp: "\ecaa";
$i-13mp: "\ecab";
$i-14mp: "\ecac";
$i-15mp: "\ecad";
$i-16mp: "\ecae";
$i-17mp: "\ecaf";
$i-18mp: "\ecb0";
$i-19mp: "\ecb1";
$i-1k: "\ecb2";
$i-1k_plus: "\ecb3";
$i-20mp: "\ecb4";
$i-21mp: "\ecb5";
$i-22mp: "\ecb6";
$i-23mp: "\ecb7";
$i-24mp: "\ecb8";
$i-2k: "\ecb9";
$i-2k_plus: "\ecba";
$i-2mp: "\ecbb";
$i-3k: "\ecbc";
$i-3k_plus: "\ecbd";
$i-3mp: "\ecbe";
$i-4k_plus: "\ecbf";
$i-4mp: "\ecc0";
$i-5k: "\ecc1";
$i-5k_plus: "\ecc2";
$i-5mp: "\ecc3";
$i-6k: "\ecc4";
$i-6k_plus: "\ecc5";
$i-6mp: "\ecc6";
$i-7k: "\ecc7";
$i-7k_plus: "\ecc8";
$i-7mp: "\ecc9";
$i-8k: "\ecca";
$i-8k_plus: "\eccb";
$i-8mp: "\eccc";
$i-9k: "\eccd";
$i-9k_plus: "\ecce";
$i-9mp: "\eccf";
$i-account_tree: "\ecd0";
$i-add_chart: "\ecd1";
$i-add_ic_call: "\ecd2";
$i-add_moderator: "\ecd3";
$i-all_inbox: "\ecd4";
$i-approval: "\ecd5";
$i-assistant_direction: "\ecd6";
$i-assistant_navigation: "\ecd7";
$i-bookmarks: "\ecd8";
$i-bus_alert: "\ecd9";
$i-cases: "\ecda";
$i-circle_notifications: "\ecdb";
$i-closed_caption_off: "\ecdc";
$i-connected_tv: "\ecdd";
$i-dangerous: "\ecde";
$i-dashboard_customize: "\ecdf";
$i-desktop_access_disabled: "\ece0";
$i-drive_file_move_outline: "\ece1";
$i-drive_file_rename_outline: "\ece2";
$i-drive_folder_upload: "\ece3";
$i-duo: "\ece4";
$i-explore_off: "\ece5";
$i-file_download_done: "\ece6";
$i-rtt: "\ece7";
$i-grid_view: "\ece8";
$i-hail: "\ece9";
$i-home_filled: "\ecea";
$i-imagesearch_roller: "\eceb";
$i-label_off: "\ecec";
$i-library_add_check: "\eced";
$i-logout: "\ecee";
$i-margin: "\ecef";
$i-mark_as_unread: "\ecf0";
$i-menu_open: "\ecf1";
$i-mp: "\ecf2";
$i-offline_share: "\ecf3";
$i-padding: "\ecf4";
$i-panorama_photosphere: "\ecf5";
$i-panorama_photosphere_select: "\ecf6";
$i-person_add_disabled: "\ecf7";
$i-phone_disabled: "\ecf8";
$i-phone_enabled: "\ecf9";
$i-pivot_table_chart: "\ecfa";
$i-print_disabled: "\ecfb";
$i-railway_alert: "\ecfc";
$i-recommend: "\ecfd";
$i-remove_done: "\ecfe";
$i-remove_moderator: "\ecff";
$i-repeat_on: "\ed00";
$i-repeat_one_on: "\ed01";
$i-replay_circle_filled: "\ed02";
$i-reset_tv: "\ed03";
$i-sd: "\ed04";
$i-shield: "\ed05";
$i-shuffle_on: "\ed06";
$i-speed: "\ed07";
$i-stacked_bar_chart: "\ed08";
$i-stream: "\ed09";
$i-swipe: "\ed0a";
$i-switch_account: "\ed0b";
$i-tag: "\ed0c";
$i-thumb_down_off_alt: "\ed0d";
$i-thumb_up_off_alt: "\ed0e";
$i-toggle_off: "\ed0f";
$i-toggle_on: "\ed10";
$i-two_wheeler: "\ed11";
$i-upload_file: "\ed12";
$i-view_in_ar: "\ed13";
$i-waterfall_chart: "\ed14";
$i-wb_shade: "\ed15";
$i-wb_twighlight: "\ed16";
$i-home_work: "\ed17";
$i-schedule_send: "\ed18";
$i-bolt: "\ed19";
$i-send_and_archive: "\ed1a";
$i-workspaces_filled: "\ed1b";
$i-file_present: "\ed1c";
$i-workspaces_outline: "\ed1d";
$i-fit_screen: "\ed1e";
$i-saved_search: "\ed1f";
$i-storefront: "\ed20";
$i-amp_stories: "\ed21";
$i-dynamic_feed: "\ed22";
$i-euro: "\ed23";
$i-height: "\ed24";
$i-policy: "\ed25";
$i-sync_alt: "\ed26";
$i-menu_book: "\ed27";
$i-emoji_flags: "\ed28";
$i-emoji_food_beverage: "\ed29";
$i-emoji_nature: "\ed2a";
$i-emoji_people: "\ed2b";
$i-emoji_symbols: "\ed2c";
$i-emoji_transportation: "\ed2d";
$i-post_add: "\ed2e";
$i-people_alt: "\ed2f";
$i-emoji_emotions: "\ed30";
$i-emoji_events: "\ed31";
$i-emoji_objects: "\ed32";
$i-sports_basketball: "\ed33";
$i-sports_cricket: "\ed34";
$i-sports_esports: "\ed35";
$i-sports_football: "\ed36";
$i-sports_golf: "\ed37";
$i-sports_hockey: "\ed38";
$i-sports_mma: "\ed39";
$i-sports_motorsports: "\ed3a";
$i-sports_rugby: "\ed3b";
$i-sports_soccer: "\ed3c";
$i-sports: "\ed3d";
$i-sports_volleyball: "\ed3e";
$i-sports_tennis: "\ed3f";
$i-sports_handball: "\ed40";
$i-sports_kabaddi: "\ed41";
$i-eco: "\ed42";
$i-museum: "\ed43";
$i-flip_camera_android: "\ed44";
$i-flip_camera_ios: "\ed45";
$i-cancel_schedule_send: "\ed46";
$i-apartment: "\ed47";
$i-bathtub: "\ed48";
$i-deck: "\ed49";
$i-fireplace: "\ed4a";
$i-house: "\ed4b";
$i-king_bed: "\ed4c";
$i-nights_stay: "\ed4d";
$i-outdoor_grill: "\ed4e";
$i-single_bed: "\ed4f";
$i-square_foot: "\ed50";
$i-double_arrow: "\ed51";
$i-sports_baseball: "\ed52";
$i-attractions: "\ed53";
$i-bakery_dining: "\ed54";
$i-breakfast_dining: "\ed55";
$i-car_rental: "\ed56";
$i-car_repair: "\ed57";
$i-dinner_dining: "\ed58";
$i-dry_cleaning: "\ed59";
$i-hardware: "\ed5a";
$i-liquor: "\ed5b";
$i-lunch_dining: "\ed5c";
$i-nightlife: "\ed5d";
$i-park: "\ed5e";
$i-ramen_dining: "\ed5f";
$i-celebration: "\ed60";
$i-theater_comedy: "\ed61";
$i-badge: "\ed62";
$i-festival: "\ed63";
$i-icecream: "\ed64";
$i-volunteer_activism: "\ed65";
$i-contactless: "\ed66";
$i-delivery_dining: "\ed67";
$i-brunch_dining: "\ed68";
$i-takeout_dining: "\ed69";
$i-ac_unit: "\ed6a";
$i-airport_shuttle: "\ed6b";
$i-all_inclusive: "\ed6c";
$i-beach_access: "\ed6d";
$i-business_center: "\ed6e";
$i-casino: "\ed6f";
$i-child_care: "\ed70";
$i-child_friendly: "\ed71";
$i-fitness_center: "\ed72";
$i-golf_course: "\ed73";
$i-hot_tub: "\ed74";
$i-kitchen: "\ed75";
$i-pool: "\ed76";
$i-room_service: "\ed77";
$i-smoke_free: "\ed78";
$i-smoking_rooms: "\ed79";
$i-spa: "\ed7a";
$i-no_meeting_room: "\ed7b";
$i-meeting_room: "\ed7c";
$i-goat: "\ed7d";
$i-5g: "\ed7e";
$i-ad_units: "\ed7f";
$i-add_business: "\ed80";
$i-add_location_alt: "\ed81";
$i-add_road: "\ed82";
$i-add_to_drive: "\ed83";
$i-addchart: "\ed84";
$i-admin_panel_settings: "\ed85";
$i-agriculture: "\ed86";
$i-alt_route: "\ed87";
$i-analytics: "\ed88";
$i-anchor: "\ed89";
$i-animation: "\ed8a";
$i-api: "\ed8b";
$i-app_blocking: "\ed8c";
$i-app_registration: "\ed8d";
$i-app_settings_alt: "\ed8e";
$i-architecture: "\ed8f";
$i-arrow_circle_down: "\ed90";
$i-arrow_circle_up: "\ed91";
$i-article: "\ed92";
$i-attach_email: "\ed93";
$i-auto_awesome: "\ed94";
$i-auto_awesome_mosaic: "\ed95";
$i-auto_awesome_motion: "\ed96";
$i-auto_delete: "\ed97";
$i-auto_fix_high: "\ed98";
$i-auto_fix_normal: "\ed99";
$i-auto_fix_off: "\ed9a";
$i-auto_stories: "\ed9b";
$i-baby_changing_station: "\ed9c";
$i-backpack: "\ed9d";
$i-backup_table: "\ed9e";
$i-batch_prediction: "\ed9f";
$i-bedtime: "\eda0";
$i-bento: "\eda1";
$i-bike_scooter: "\eda2";
$i-biotech: "\eda3";
$i-block_flipped: "\eda4";
$i-browser_not_supported: "\eda5";
$i-build_circle: "\eda6";
$i-calculate: "\eda7";
$i-campaign: "\eda8";
$i-carpenter: "\eda9";
$i-cast_for_education: "\edaa";
$i-charging_station: "\edab";
$i-checkroom: "\edac";
$i-circle: "\edad";
$i-cleaning_services: "\edae";
$i-close_fullscreen: "\edaf";
$i-closed_caption_disabled: "\edb0";
$i-comment_bank: "\edb1";
$i-construction: "\edb2";
$i-corporate_fare: "\edb3";
$i-countertops: "\edb4";
$i-design_services: "\edb5";
$i-directions_off: "\edb6";
$i-dirty_lens: "\edb7";
$i-do_not_step: "\edb8";
$i-do_not_touch: "\edb9";
$i-domain_verification: "\edba";
$i-drive_file_move: "\edbb";
$i-dry: "\edbc";
$i-dynamic_form: "\edbd";
$i-east: "\edbe";
$i-edit_road: "\edbf";
$i-electric_bike: "\edc0";
$i-electric_car: "\edc1";
$i-electric_moped: "\edc2";
$i-electric_rickshaw: "\edc3";
$i-electric_scooter: "\edc4";
$i-electrical_services: "\edc5";
$i-elevator: "\edc6";
$i-engineering: "\edc7";
$i-escalator: "\edc8";
$i-escalator_warning: "\edc9";
$i-face_retouching_natural: "\edca";
$i-fact_check: "\edcb";
$i-family_restroom: "\edcc";
$i-fence: "\edcd";
$i-filter_alt: "\edce";
$i-fire_extinguisher: "\edcf";
$i-flaky: "\edd0";
$i-food_bank: "\edd1";
$i-forward_to_inbox: "\edd2";
$i-foundation: "\edd3";
$i-grading: "\edd4";
$i-grass: "\edd5";
$i-handyman: "\edd6";
$i-hdr_enhanced_select: "\edd7";
$i-hearing_disabled: "\edd8";
$i-help_center: "\edd9";
$i-highlight_alt: "\edda";
$i-history_edu: "\eddb";
$i-history_toggle_off: "\eddc";
$i-home_repair_service: "\eddd";
$i-horizontal_rule: "\edde";
$i-hourglass_bottom: "\eddf";
$i-hourglass_disabled: "\ede0";
$i-hourglass_top: "\ede1";
$i-house_siding: "\ede2";
$i-hvac: "\ede3";
$i-image_not_supported: "\ede4";
$i-insights: "\ede5";
$i-integration_instructions: "\ede6";
$i-ios_share: "\ede7";
$i-legend_toggle: "\ede8";
$i-local_fire_department: "\ede9";
$i-local_police: "\edea";
$i-location_pin: "\edeb";
$i-lock_clock: "\edec";
$i-login: "\eded";
$i-maps_ugc: "\edee";
$i-mark_chat_read: "\edef";
$i-mark_chat_unread: "\edf0";
$i-mark_email_read: "\edf1";
$i-mark_email_unread: "\edf2";
$i-mediation: "\edf3";
$i-medical_services: "\edf4";
$i-mic_external_off: "\edf5";
$i-mic_external_on: "\edf6";
$i-microwave: "\edf7";
$i-military_tech: "\edf8";
$i-miscellaneous_services: "\edf9";
$i-model_training: "\edfa";
$i-monitor: "\edfb";
$i-moped: "\edfc";
$i-more_time: "\edfd";
$i-motion_photos_off: "\edfe";
$i-motion_photos_on: "\edff";
$i-motion_photos_paused: "\ee00";
$i-multiple_stop: "\ee01";
$i-nat: "\ee02";
$i-near_me_disabled: "\ee03";
$i-next_plan: "\ee04";
$i-night_shelter: "\ee05";
$i-nightlight_round: "\ee06";
$i-no_cell: "\ee07";
$i-no_drinks: "\ee08";
$i-no_flash: "\ee09";
$i-no_food: "\ee0a";
$i-no_meals: "\ee0b";
$i-no_photography: "\ee0c";
$i-no_stroller: "\ee0d";
$i-no_transfer: "\ee0e";
$i-north: "\ee0f";
$i-north_east: "\ee10";
$i-north_west: "\ee11";
$i-not_accessible: "\ee12";
$i-not_started: "\ee13";
$i-online_prediction: "\ee14";
$i-open_in_full: "\ee15";
$i-outbox: "\ee16";
$i-outgoing_mail: "\ee17";
$i-outlet: "\ee18";
$i-panorama_horizontal_select: "\ee19";
$i-panorama_vertical_select: "\ee1a";
$i-panorama_wide_angle_select: "\ee1b";
$i-payments: "\ee1c";
$i-pedal_bike: "\ee1d";
$i-pending: "\ee1e";
$i-pending_actions: "\ee1f";
$i-person_add_alt: "\ee20";
$i-person_add_alt_1: "\ee21";
$i-person_remove: "\ee22";
$i-person_search: "\ee23";
$i-pest_control: "\ee24";
$i-pest_control_rodent: "\ee25";
$i-photo_camera_back: "\ee26";
$i-photo_camera_front: "\ee27";
$i-plagiarism: "\ee28";
$i-play_disabled: "\ee29";
$i-plumbing: "\ee2a";
$i-point_of_sale: "\ee2b";
$i-preview: "\ee2c";
$i-privacy_tip: "\ee2d";
$i-psychology: "\ee2e";
$i-public_off: "\ee2f";
$i-push_pin: "\ee30";
$i-qr_code: "\ee31";
$i-qr_code_scanner: "\ee32";
$i-quickreply: "\ee33";
$i-read_more: "\ee34";
$i-receipt_long: "\ee35";
$i-request_quote: "\ee36";
$i-rice_bowl: "\ee37";
$i-roofing: "\ee38";
$i-room_preferences: "\ee39";
$i-rule: "\ee3a";
$i-rule_folder: "\ee3b";
$i-run_circle: "\ee3c";
$i-science: "\ee3d";
$i-screen_search_desktop: "\ee3e";
$i-search_off: "\ee3f";
$i-self_improvement: "\ee40";
$i-sensor_door: "\ee41";
$i-sensor_window: "\ee42";
$i-set_meal: "\ee43";
$i-shopping_bag: "\ee44";
$i-signal_cellular_0_bar: "\ee45";
$i-signal_wifi_0_bar: "\ee46";
$i-smart_button: "\ee47";
$i-snippet_folder: "\ee48";
$i-soap: "\ee49";
$i-source: "\ee4a";
$i-south: "\ee4b";
$i-south_east: "\ee4c";
$i-south_west: "\ee4d";
$i-sports_bar: "\ee4e";
$i-stairs: "\ee4f";
$i-star_outline1: "\ee50";
$i-star_rate: "\ee51";
$i-sticky_note_2: "\ee52";
$i-stop_circle: "\ee53";
$i-stroller: "\ee54";
$i-subscript: "\ee55";
$i-subtitles_off: "\ee56";
$i-superscript: "\ee57";
$i-support: "\ee58";
$i-support_agent: "\ee59";
$i-switch_left: "\ee5a";
$i-switch_right: "\ee5b";
$i-table_rows: "\ee5c";
$i-table_view: "\ee5d";
$i-tapas: "\ee5e";
$i-taxi_alert: "\ee5f";
$i-text_snippet: "\ee60";
$i-tour: "\ee61";
$i-tty: "\ee62";
$i-umbrella: "\ee63";
$i-upgrade: "\ee64";
$i-verified: "\ee65";
$i-video_settings: "\ee66";
$i-view_sidebar: "\ee67";
$i-wash: "\ee68";
$i-water_damage: "\ee69";
$i-west: "\ee6a";
$i-wheelchair_pickup: "\ee6b";
$i-wifi_calling: "\ee6c";
$i-wifi_protected_setup: "\ee6d";
$i-wine_bar: "\ee6e";
$i-wrong_location: "\ee6f";
$i-wysiwyg: "\ee70";
$i-leaderboard: "\ee71";
$i-6_ft_apart: "\ee72";
$i-book_online: "\ee73";
$i-clean_hands: "\ee74";
$i-connect_without_contact: "\ee75";
$i-coronavirus: "\ee76";
$i-elderly: "\ee77";
$i-follow_the_signs: "\ee78";
$i-leave_bags_at_home: "\ee79";
$i-masks: "\ee7a";
$i-reduce_capacity: "\ee7b";
$i-sanitizer: "\ee7c";
$i-send_to_mobile: "\ee7d";
$i-sick: "\ee7e";
$i-add_task: "\ee7f";
$i-contact_page: "\ee80";
$i-disabled_by_default: "\ee81";
$i-facebook: "\ee82";
$i-groups: "\ee83";
$i-luggage: "\ee84";
$i-no_backpack: "\ee85";
$i-no_luggage: "\ee86";
$i-outbond: "\ee87";
$i-published_with_changes: "\ee88";
$i-request_page: "\ee89";
$i-stacked_line_chart: "\ee8a";
$i-unpublished: "\ee8b";
$i-align_horizontal_center: "\ee8c";
$i-align_horizontal_left: "\ee8d";
$i-align_horizontal_right: "\ee8e";
$i-align_vertical_bottom: "\ee8f";
$i-align_vertical_center: "\ee90";
$i-align_vertical_top: "\ee91";
$i-horizontal_distribute: "\ee92";
$i-qr_code_2: "\ee93";
$i-update_disabled: "\ee94";
$i-vertical_distribute: "\ee95";

