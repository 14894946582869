// ------------------------ //
// ***** WEIGHT CHART ***** //
// ------------------------ //
// #region -= WEIGHT CHART
// 100    Extra Light or Ultra Light
// 200    Light / Thin
// 300    Book or Demi / Thin
// 400    Normal or Regular
// 500    Medium
// 600    Semibold, Demibold
// 700    Bold
// 800    Black, Extra Bold or Heavy
// 900    Extra Black, Fat, Poster or Ultra Black
// #endregion -= WEIGHT CHART

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;700;900&display=swap");

@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
.subTitle {
  font-size: 24px;
  line-height: 150%;
  @include font($fontB);
}

.eyebrowText {
  font-size: 18px;
  line-height: 100%;
  @include font($fontB);
  // letter-spacing: -0.02em;
}

.legal {
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0;
  @include font($font);
}

@include media-breakpoint-down(map-get($global, device-switch)) {
  .eyebrowText {
    line-height: 125%;
  }
}
