body {
  &.onLoading {
    &:after,
    &:before {
      content: "";
      position: fixed;
    }
    &:before {
      z-index: 9999;
      pointer-events: all;
      background-color: rgba(#ccc, 0.8) !important;
      @extend .absFC;
    }
    &:after {
      top: 50%;
      left: 50%;
      z-index: 10000;
      @extend .loader;
      border-color: rgba(#000, 0.3);
      margin: rem(60px * 0.5) 0 0 rem(-60px * 0.5);
      border-top-color: map-get($theme, accent);
    }
    &[data-loader-message]:after {
      left: 0;
      right: 0;
      top: 50%;
      z-index: 10001;
      margin: 0 auto;
      display: block;
      color: map-get($theme, color);
      background: #fff;
      position: absolute;
      text-align: center;
      margin-top: rem(25px);
      content: attr(data-loader-message);
    }
  }
}

main {
}

.page {
  // padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left);
  // padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  max-height: 100vh;
  position: absolute;
  min-height: rem(1920px);
  background: map-get($theme, bg); // height: 100vh;
  // overflow: scroll; // /* has to be scroll, not auto
  // overflow-x: hidden;
  // overflow-y: scroll;
  // -webkit-overflow-scrolling: touch;
  // @extend %animateFast;
}
.common_btn {
  width: 100%;
  max-width: 210px;
  padding: 14px;
  font-size: 22px;
  font-weight: bold;
  font-family: map-get($theme, fontFamily);
  margin: auto;
  text-align: center;
  justify-content: center;
  border-radius: 50px;
}
.center_btn {
  text-align: center;
  margin-top: 80px;
}
.h2[aria-details]:before {
  left: 0;
  font-size: 300px;
  line-height: 1em;
  position: absolute;
  content: attr(aria-details);
  color: #f3f4f7;
  top: 7px;
  right: 0;
  margin-top: 30px;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
section {
  .top_heading {
  }
}
.container-md {
  max-width: 1024px;
  --bs-gutter-x: 0px;
  @include media-breakpoint-down(xl) {
    --bs-gutter-x: 30px;
  }
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.swiper-pagination {
  display: none;
}
.tempus-dominus-widget .toolbar div {
  border-radius: 999px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  display: none;
}
.SumoSelect:focus > .CaptionCont {
  box-shadow: 0 0 0 0.2rem rgba(84, 223, 254, 0);
}
.screen-reader-response {
  display: none;
}
.wpcf7-not-valid-tip {
  color: red;
}
.i4ewOd-pzNkMb-haAclf {
  background-color: #2e312f;
  display: none;
}
.HzV7m-b7CEbf.neVct-IlgHgd .jQhVs-haAclf {
  position: absolute;
  width: 308px;
  height: 52vh;
  bottom: 0;
}
.emptydata {
  text-align: center;
  font-size: 21px;
  color: #094268;
  background: #54dffe;
  padding: 15px 0px;
  font-weight: bold;
  border-radius: 10px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  margin-bottom: 50px;
}
.time-picker {
  width: 400px;
  height: 2px;
  background-color: #ccc;
  position: relative;
  margin: 50px auto;
}

.time-marker {
  font-size: smaller;
  position: absolute;
  top: calc(50% - 0.75rem);
  z-index: 2;
  height: 1.5rem;
   width: auto; 
  white-space: nowrap; 
   text-align: left; 
  cursor: pointer; 
  box-shadow: 0 0 0.25rem var(--next-color-primary,rgba(25,25,25,0.5));
  background-color: var(--next-color-background,rgba(255,255,255,1));
  border: 2px solid var(--next-color-highlight,#54dffe);
   border-radius: 0.75rem; 
   outline: none; 
   transform: translateX(-50%); 
   transform-origin: 50% 50%; 
   display: flex; 
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 0.75rem;
}

.selected-time {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
}

#time-picker-container, #time-picker-container1{
  margin-top: 5px;
  height: 60px;
  display: flex;
  width: 265px;
  padding: 0px 52px;
  left: -30%;
      position: relative ;
}

@media only screen and (max-width: 992px){
  #time-picker-container, #time-picker-container1{
    width: 100%;
     left: 0%;
      position: relative;
  }

}
@media screen and (min-width: 767px) {
  .optWrapper {
    width: max-content!important;
  }

}