html[dir="rtl"] {
  &,
  body {
    --app-primary-font: #{map-get($theme, ar-primary-font)};
    --app-secondary-font: #{map-get($theme, ar-primary-font)};
  }
  .topFilter {
    i {
      transform: rotate(180deg);
    }
  }
  .form_part {
    .form_heading {
      text-align: right;
    }
    .form_inner {
      .inner_sec {
        input {
          text-align: right;
        }
      }
      .other_heading {
        text-align: right;
      }
    }
  }
}
