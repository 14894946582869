[data-bs-toggle="collapse"].collapser {
  display: block;
  position: relative;
  &:after,
  &:before {
    content: "";
    position: absolute;
    @extend %animateFast;
  }
  &:before {
    @extend .i;
    z-index: 1;
    bottom: rem(0px);
    content: $i-add_circle;
    color: map-get($theme, color);
  }
  &[aria-expanded="true"] {
    &:before {
      content: $i-remove_circle;
    }
  }
  &.withArrow {
    font-size: rem(29px);
    margin-top: rem(50px);
    margin-bottom: rem(35px);
    padding-bottom: rem(35px);
    line-height: em(30px, 29px);
    color: map-get($theme, color);
    @include font(map-get($theme, fontB));
    border-bottom: rem(7px) solid map-get($theme, brdr);
    &:after,
    &:before {
      right: rem(80px);
    }
    &:before {
      margin-right: rem(24px);
      content: $i-keyboard_arrow_down;
      font-size: em(26.7px, 29px);
    }
    &:after {
      top: 100%;
      z-index: 0;
      width: rem(45px);
      height: rem(45px);
      background: #fff;
      border-style: solid;
      border-color: map-get($theme, brdr);
      border-width: 0 rem(7px) rem(7px) 0;
      @include prefix(
        (
          transform: rotate(37deg) skewX(-20deg),
          transform-origin: right top,
        ),
        webkit ms
      );
      margin-top: rem(5px);
    }
    &[aria-expanded="true"] {
      &:before {
        content: $i-keyboard_arrow_down;
        bottom: rem(-32px);
        @include prefix(
          (
            transform: rotate(180deg),
          ),
          webkit ms
        );
      }
      &:after {
        top: 100%;
        margin-top: rem(3px);
        @include prefix(
          (
            transform: rotate(37deg) skewX(-20deg) scale(-1) translate(rem(45px), rem(-45px)),
          ),
          webkit ms
        );
      }
    }
  }
  &.plusMinus {
    &:before {
      content: $i-add_circle;
    }
    &[aria-expanded="true"] {
      &:before {
        content: $i-remove_circle;
      }
    }
  }
}
