@use "sass:map";

$header: map-get($theme, header);

header.header {
  color: map-get($header, text);
  background: map-get($header, bg);
  max-width: 1320px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 87px;
  border-radius: 0px 0px 16px 16px;
  position: fixed;
  z-index: 9000;
  @include media-breakpoint-down(lg) {
  }
  .bg-primary {
    background: none;
  }
  .mainMenu {
    .navbar-nav {
      margin-left: auto;
      &.navbar-nav-arb {
        margin-left: 0;
        margin-right: auto;
      }
      .nav-link {
        padding-right: 15px;
        padding-left: 15px;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
  .dropdown {
    &-item {
      @include font($fontB);
    }
    &-menu {
      @extend %animateFast;
      & {
        --bs-dropdown-border-radius: 10px;
        // --bs-dropdown-item-padding-x: 20px;
        // --bs-dropdown-item-padding-y: 10px;
        // --bs-dropdown-border-color: transparent;
        // --bs-dropdown-link-color: #{map-get($header, link)};
        // --bs-dropdown-link-hover-color: #{map-get($header, link)};
        // --bs-dropdown-link-hover-bg: #{map-get($header, ddHoverBg)};
        // --bs-dropdown-link-active-bg: #{map-get($header, ddActiveBg)};
        // --bs-dropdown-link-active-color: #{map-get($header, ddActiveTxt)};
        // --bs-dropdown-box-shadow: 0px 3px 6px #{rgba(map-get($color, dark), 0.16)};
        // --bs-dropdown-color: #212529;
        // --bs-dropdown-zindex: 1000;
        // --bs-dropdown-padding-x: 0;
        // --bs-dropdown-padding-y: 0.5rem;
        // --bs-dropdown-font-size: 1rem;
        // --bs-dropdown-min-width: 10rem;
        // --bs-dropdown-spacer: 0.125rem;
        // --bs-dropdown-bg: #fff;
        // --bs-dropdown-border-width: 1px;
        // --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
        // --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
        // --bs-dropdown-divider-margin-y: 0.5rem;
        // --bs-dropdown-link-disabled-color: #adb5bd;
        // --bs-dropdown-header-color: #6c757d;
        // --bs-dropdown-header-padding-x: 1rem;
        // --bs-dropdown-header-padding-y: 0.5rem;
      }
      min-width: 100%;
      box-shadow: var(--bs-dropdown-box-shadow);
    }
  }

  .navbar {
    &-dark {
    }
    .nav-link {
      line-height: 1em;
      @extend %animateFast;
      @include font($fontB);
      // & > span {
      //   position: relative;
      //   &:before {
      //     left: 0;
      //     right: 0;
      //     width: 0%;
      //     height: 1px;
      //     content: "";
      //     opacity: 0.5;
      //     margin: auto;
      //     bottom: -10px;
      //     position: absolute;
      //     @extend %animateFast;
      //   }
      // }
    }
    .nav-item {
      --bs-nav-link-font-size: #{map.get($theme, baseFontSize)};
      // &:not(:last-child) {
      //   border-right: 1px solid map-get($header, brdr);
      // }
      // &.hasBtn {
      //   .nav-link {
      //     color: #{map-get($header, linkSpot)};
      //   }
      // }
    }
    &-nav {
      --bs-nav-link-padding-x: 0;
      --bs-nav-link-padding-y: 14.5px;
      --bs-navbar-nav-link-padding-x: 24px;
      --bs-nav-link-color: #{map-get($header, link)};
      --bs-navbar-active-color: #{map-get($header, linkActive)};
      --bs-nav-link-hover-color: #{map-get($header, linkHover)};
      // --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
    }
    &-light {
      .navbar-nav {
        --bs-nav-link-color: #{map-get($theme, color)};
        --bs-navbar-active-color: #{map-get($theme, color)};
      }
    }
  }
}
.navbar-toggler {
  border: none;
  &:focus {
    box-shadow: none !important;
  }
  .navbar-toggler-icon {
    i {
      font-size: 30px;
    }
  }
}
.navbar-collapse {
  background: #54dffe;
  z-index: 99;
}
.navbar {
  .container-fluid {
    .navbar-brand {
      picture {
        img {
          width: 240px;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      padding: 0px;
      .navbar-brand {
        margin-left: 20px;
        picture {
          img {
          }
        }
      }
      .navbar-collapse {
        padding-left: 10px;
        margin-top: -8px;
        padding-top: 10px;
      }
    }
  }
}
.nav-link {
  .nav-link-color {
    color: map-get($theme, text);
  }
  .nav-link-span {
    padding: 0px 0px;
  }
}
