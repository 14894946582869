@import "theme";
@import "bsVars";

$baseFontSize: map-get($theme, baseFontSize);
$base-font-size: $baseFontSize;

$Txt: map-get($theme, text);

$prLink: map-get($theme, link);
$prFont: map-get($theme, font);

$colorType: $color;

$brdRad: map-get($global, border-radius);
$matBorderRadius: map-get($global, border-radius);

// HD: 1920 X 1080
// $minWidth: 1080px;
// 4k: 3840 X 2160
// $maxWidth: 3840px;

$img: "../images";
$fonts: "../fonts";

$owl-image-path: "#{$img}/";

$icomoon-font-path: "#{$fonts}/icon";
$fi-font-path: "#{$fonts}/icon";
