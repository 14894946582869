.swiper {
  &-slide {
    overflow: hidden;
  }

  &-button {
    &-prev,
    &-next {
      &:after {
        font-family: cssVar(iconFont);
      }
    }
  }
}

// .owl {
//   &-dots {
//     text-align: center;
//     -webkit-tap-highlight-color: transparent;

//     .owl-dot {
//       display: inline-block;
//       zoom: 1;
//       *display: inline;
//       counter-increment: slide;

//       span {
//         display: block;
//         margin: rem(5px);
//         width: rem(12px);
//         height: rem(12px);
//         position: relative;
//         border-radius: 50%;
//         color: map-get($theme, write);
//         -webkit-backface-visibility: visible;
//         border: map-get($theme, write) rem(2px) solid;
//         @extend %animateFast;
//       }

//       &.active,
//       &:hover {
//         span {
//           background: map-get($theme, write);
//           @include box-shadow(0 0 #{rem(10px)} #000);
//         }
//       }
//       // &:hover {
//       //     span {
//       //     }
//       // }
//     }
//   }

//   &-carousel {
//     .owl-nav {
//       left: 0;
//       top: 50%;
//       width: 100%;
//       position: absolute;
//       pointer-events: none;
//       margin-top: rem(-60px);
//       & > * {
//         pointer-events: all;
//       }
//       .i {
//         font-size: rem(44px);
//       }
//       button {
//         &.owl-prev,
//         &.owl-next {
//           position: absolute;
//           color: map-get($theme, text);
//           @extend %animateFast;
//           &:hover,
//           &:focus,
//           &:active {
//             color: map-get($theme, text);
//           }
//         }
//         &.owl-prev {
//           // left: rem(-60px);
//           left: rem(-24px);
//         }
//         &.owl-next {
//           // right: rem(-60px);
//           right: rem(-25px);
//         }
//       }
//     }
//   }
// }
