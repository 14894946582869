/**!
 * @name NitroToast
 * @version 1.1.0
 * @author Ajith S Punalur
 * @license MIT
 * @release 01-02-2021
 **/

.nmToast,
.snackbar {
  bottom: 0;
  #{$left}: 0;
  #{$right}: 0;
  width: 100%;
  color: #fff;
  z-index: 2000;
  margin: 0 auto;
  cursor: default;
  position: fixed;
  min-height: 48px;
  max-height: 80px;
  visibility: hidden;
  pointer-events: none;
  will-change: transform;
  background-color: #323232;
  transition: transform 0.25s cubic-bezier(0.4, 0, 1, 1);
  transition: transform 0.25s cubic-bezier(0.4, 0, 1, 1), -webkit-transform 0.25s cubic-bezier(0.4, 0, 1, 1);

  @include prefix(
    (
      transform: translate(0, 80px),
    ),
    webkit ms
  );
  @include justify-content(space-between);

  @extend %flexbox;

  &-text {
    padding: 14px 12px 14px 24px;
  }

  &-action {
    @include font($fw-m);
    background: transparent;
    text-transform: uppercase;
    padding: 14px 24px 14px 12px;
    color: map-get($matColor, info); //#ff4081;
    transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1);
    transition: transform 0.25s cubic-bezier(0, 0, 0.2, 1), -webkit-transform 0.25s cubic-bezier(0, 0, 0.2, 1);

    @extend .btn !optional;

    &:hover,
    &:focus,
    &:active {
      color: map-get($matColor, info); //#ff4081;
    }
  }

  &.active {
    visibility: visible;
    pointer-events: all;

    @include prefix(
      (
        transform: translate(0, 0),
      ),
      webkit ms
    );
  }

  @media only screen and (min-width: 480px) {
    min-width: 288px;
    max-width: 568px;
  }

  &[data-placement="top"],
  &[data-placement="bottom"],
  &[data-placement="top-left"],
  &[data-placement="top-right"],
  &[data-placement="bottom-left"],
  &[data-placement="bottom-right"] {
    &.active {
      @include prefix(
        (
          transform: translate(0, 0),
        ),
        webkit ms
      );
    }
  }

  &[data-placement="top-left"],
  &[data-placement="bottom-left"] {
    left: 10px;
    margin-left: 0;
  }

  &[data-placement="top-right"],
  &[data-placement="bottom-right"] {
    right: 10px;
    margin-right: 0;
  }

  &[data-placement="top-left"],
  &[data-placement="top-right"] {
    top: 10px;
    bottom: auto;
  }

  &[data-placement="bottom-left"],
  &[data-placement="bottom-right"] {
    top: auto;
    bottom: 10px;
  }

  &[data-placement="top"] {
    top: 0;
    bottom: auto;

    @include prefix(
      (
        transform: translate(0, -80px),
      ),
      webkit ms
    );
  }

  &[data-placement="bottom"] {
    top: auto;
    bottom: 0px;

    @include prefix(
      (
        transform: translate(0, 80px),
      ),
      webkit ms
    );
  }

  &[data-placement="top-left"],
  &[data-placement="bottom-left"] {
    @include prefix(
      (
        transform: translate(-105%, 0),
      ),
      webkit ms
    );
  }

  &[data-placement="top-right"],
  &[data-placement="bottom-right"] {
    @include prefix(
      (
        transform: translate(105%, 0),
      ),
      webkit ms
    );
  }
}
