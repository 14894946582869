// @import
// 	"../plugins/NitroMaterial/style";

// /* **********************
// /* ******** OR **********
// /* Choose a custom buid
// /* **********************/
@import "../plugins/NitroMaterial/matVariables"; // Don't edit this file directly
// if Overrides with vars change below this line

// $mat: map-merge(
//   $mat,
//   (
//     font: map-get($theme, font),
//     fontM: map-get($theme, fontM),
//     color: map-get($theme, color),
//     ctrlGutter: 1em,
//     border: rgba(#000, 0.4),
//     placeholder: rgba(#000, 0.42),
//     kind: (
//       primary: map-get($color, primary),
//       accent: map-get($color, accent),
//       white: #fff,
//       default: #fff,
//       info: #039be5,
//       warn: #ffb74d,
//       error: #b92d00,
//       success: #009688,
//     ),
//   )
// );

// @import "../plugins/NitroMaterial/common";
// @import "../plugins/NitroMaterial/matButton";
@import "../plugins/NitroMaterial/matToggle";

// @import "../plugins/NitroMaterial/matForm"; // (contains both common, matButton, matToggle);

// MatComponents
@import "../plugins/NitroMaterial/dialog";
@import "../plugins/NitroMaterial/snackBar";
@import "../plugins/NitroMaterial/matCard";
// @import "../plugins/NitroMaterial/matTabs";
// @import "../plugins/NitroMaterial/matList";
// @import "../plugins/NitroMaterial/matFilter";
// @import "../plugins/NitroMaterial/pagination";
// @import "../plugins/NitroMaterial/matDataTable";

/* THEME */
// @import "../plugins/NitroMaterial/theme/primary";
// @import "../plugins/NitroMaterial/theme/invert";
