:root {
  // THEME
  @each $key, $value in $theme {
    @if (type-of($value) != "map") {
      --#{$cssVarPrefix}-#{$key}: #{$value};
      @if (type-of($value) == color) {
        --#{$cssVarPrefix}-#{$key}-rgb: #{toRGBList($value)};
      }
    }
  }
  // COLOR
  @each $key, $value in $color {
    @if (type-of($value) != "map") {
      --#{$cssVarPrefix}-#{$key}: #{$value};
      @if (type-of($value) == color) {
        --#{$cssVarPrefix}-#{$key}-rgb: #{toRGBList($value)};
      }
    }
  }
  // CONTRAST
  @each $key, $value in $cont {
    @if (type-of($value) != "map") {
      --#{$cssVarPrefix}-cont-#{$key}: #{$value};
      @if (type-of($value) == color) {
        --#{$cssVarPrefix}-cont-#{$key}-rgb: #{toRGBList($value)};
      }
    }
  }

  // @each $key, $value in map-get($theme, types_light) {
  //   @if (type-of($value) != "map") {
  //     --#{$cssVarPrefix}-lighten-#{$key}: #{$value};
  //     @if (type-of($value) == color) {
  //       --#{$cssVarPrefix}-lighten-#{$key}-rgb: #{toRGBList($value)};
  //     }
  //   }
  // }

  // @each $key, $value in map-get($theme, cont_light) {
  //   @if (type-of($value) != "map") {
  //     --#{$cssVarPrefix}-cont-lighten-#{$key}: #{$value};
  //     @if (type-of($value) == color) {
  //       --#{$cssVarPrefix}-cont-lighten-#{$key}-rgb: #{toRGBList($value)};
  //     }
  //   }
  // }

  // HOVER
  @each $key, $value in map-get($color, hover) {
    @if (type-of($value) != "map") {
      --#{$cssVarPrefix}-hover-#{$key}: #{$value};
      @if (type-of($value) == color) {
        --#{$cssVarPrefix}-hover-#{$key}-rgb: #{toRGBList($value)};
      }
    }
  }

  // @each $key, $value in $colorGradient {
  //   @if (type-of($value) != "map") {
  //     @if (type-of($value) == list) {
  //       @each $color in $value {
  //         --#{$cssVarPrefix}-gradient-#{$key}-#{list.index($value, $color)}: #{$color};
  //         @if (type-of($color) == color) {
  //           --#{$cssVarPrefix}-gradient-#{$key}-#{list.index($value, $color)}-rgb: #{toRGBList($color)};
  //         }
  //       }
  //     } @else if (type-of($value) == color) {
  //       --#{$cssVarPrefix}-gradient-#{$key}: #{$value};
  //       --#{$cssVarPrefix}-gradient-#{$key}-rgb: #{toRGBList($value)};
  //     }
  //   }
  // }

  // HEADER
  @each $key, $value in map-get($theme, header) {
    @if (type-of($value) != "map") {
      --#{$cssVarPrefix}-header-#{$key}: #{$value};
      @if (type-of($value) == color) {
        --#{$cssVarPrefix}-header-#{$key}-rgb: #{toRGBList($value)};
      }
    }
  }
  // FOOTER
  @each $key, $value in map-get($theme, footer) {
    @if (type-of($value) != "map") {
      --#{$cssVarPrefix}-footer-#{$key}: #{$value};
      @if (type-of($value) == color) {
        --#{$cssVarPrefix}-footer-#{$key}-rgb: #{toRGBList($value)};
      }
    }
  }
}
