.top_back {
  padding-top: 100px;

  p {
    padding: 0px;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 0;
    i {
      color: map-get($theme, text);
      font-size: 22px;
      margin-right: 10px;
    }
    a {
      color: map-get($theme, text);
      display: flex;
    }
  }
}
.form_section {
  margin-top: 20px;
  margin-bottom: 150px;
  .img_part {
    box-shadow: 0px 0px 5px -1px gray;
    border-radius: 20px;
    padding: 20px 20px 50px 20px;
    picture {
      width: 100%;
      margin-top: 40px;
      margin-bottom: 15px;
      img {
        width: 100%;
      }
    }
    .rental_days {
      font-family: map-get($theme, fontFamily);
      color: map-get($theme, text);
      font-size: 12px;
      text-align: center;
    }
  }
  .icons_lsiting {
    text-align: center;
    .car_details {
      &.car_details_top {
        margin-top: 35px;
      }
      i {
        font-size: 30px;
        color: map-get($theme, cartext);
        @media (min-width: 1200px) and (max-width: 1400px) {
          font-size: 25px;
        }
      }
      p {
        color: map-get($theme, textL);
        font-size: 10px;
        font-weight: 400;

        font-family: map-get($theme, fontFamily);
        @media (min-width: 1200px) and (max-width: 1400px) {
          font-size: 7px;
        }
      }
    }
  }
  .form_part {
    margin-top: 80px;
    padding: 0px 25px;
    .form_heading {
      margin-bottom: 40px;
      text-align: left;
      padding: 0;
    }
    .form_inner {
      .inner_sec {
        margin-top: 30px;
        p {
          padding: 0;
        }
        input {
          border: none;
          border-bottom: 1px solid gray;
          margin-bottom: 10px;
          height: 35px;
          padding: 0px;
          width: 100%;
        }
        .form_divided {
          padding: 0px;
          .input_code {
            width: 75%;
          }
        }
      }
      .other_heading {
        margin-top: 40px;
        text-align: left;
        margin-bottom: 20px;
        padding: 0;
        font-size: 22px;
        line-height: 28px;
        font-weight: 400;
      }
    }
  }
  .confirm_msg {
    padding: 0px 15px;
    margin-top: 50px;
    .confirm_heading {
      font-size: 25px;
      font-weight: bold;
      font-family: map-get($theme, fontFamily);
      line-height: 33px;
    }
    .coustmer_name {
      margin-top: 10px;
      font-weight: 500;
      font-size: 18px;
      font-family: map-get($theme, fontFamily);
    }
    .coustmer_confirm {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0px;
      font-family: map-get($theme, fontFamily);
    }
    table {
      margin: 15px;
      font-family: map-get($theme, fontFamily);
      tr{
        @include media-breakpoint-down(lg) {
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
        }
      
      }
      .booking_head {
        font-family: map-get($theme, fontFamily);
        font-size: 15px;
        font-weight: 500;
        padding-right: 15px;
        display: flex;
        
      }
      .booking_det {
        font-family: map-get($theme, fontFamily);
        font-size: 15px;
        font-weight: 500;
      }
    }
    .booking_content {
      font-size: 14px;
      line-height: 22px;
      text-align: justify;
      font-family: map-get($theme, fontFamily);
    }
    .booking_last_content {
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
    }
    .booking_thanks {
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
      margin-bottom: 0;
    }
    .booking_coustmer {
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
      margin-bottom: 30px;
    }
  }
  .booking_pricing_section {
    box-shadow: 0px 0px 5px -1px gray;
    border-radius: 20px;
    padding: 50px 20px 35px 20px;
    padding: 50px 20px 35px 20px;

    &.border_none {
      box-shadow: 0px 0px 0px 0px gray;
      padding: 50px 0px 35px 0px;
      .check_sec {
        padding-left: 30px;
        padding-right: 30px;
        @include media-breakpoint-down(md) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      .total_pricing {
        padding: 40px 30px 0px 30px;
      }
      .price_breakdown {
        padding: 40px 30px 0px 30px;
      }
    }
    @include media-breakpoint-down(lg) {
      margin-left: -15px;
      margin-top: 30px;
    }
    .check_sec {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding-right: 0;
      padding-left: 0;
      i {
        color: map-get($theme, text);
        font-size: 16px;
        margin-right: 14px;
        font-weight: bold;
        margin-left: 14px;
        &.error {
          margin-right: 0px;
        }
      }
      p {
        font-size: 14px;
        line-height: 22px;
        color: map-get($theme, text);
        margin-bottom: 0px;
        width: 100%;
      }
    }
    .total_pricing {
      padding: 40px 0px 0px 45px;
      .pricing_inner {
        margin-bottom: 0px;
        font-family: map-get($theme, fontFamily);
        font-size: 18px;
        border-bottom: 1px solid #4c5b77;
        padding-bottom: 20px;
        p {
          display: flex;
          justify-content: space-between;
          .total {
            color: map-get($theme, text);

            font-size: 18px;
            font-weight: 500;
          }
          .pricing {
            color: #00a0c4;
            font-weight: bold;
          }
          .pricing_subtext {
            font-family: map-get($theme, fontFamily);
            font-size: 12px;
            font-weight: 500;
            margin-top: 30px;
          }
        }
      }
    }
    .price_breakdown {
      padding: 40px 0px 0px 45px;
      .price_breakdown_head {
        font-weight: 500;
        margin-bottom: 35px;
        font-size: 22px;
        line-height: 28px;
      }
      .price_breakdown_details {
        .price_breakdown_inner {
          margin-bottom: 0px;
          padding-bottom: 20px;
          font-family: map-get($theme, fontFamily);
          color: map-get($theme, text);
          display: flex;
          justify-content: space-between;
          .content {
            font-size: 14px;
            font-weight: 400;
          }
          .pricing_breakdown {
            font-size: 14px;
            font-weight: bold;
          }
          &.dotted_border {
            border-bottom: 1px dotted #4c5b77;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .booking-details {
    padding-left: 30px;
    .car_name {
      margin-top: 30px;
      margin-bottom: 10px;
      margin-left: 0;
      font-size: 22px;
      line-height: 28px;
      margin-left: auto;
      font-weight: 400;
    }
    .car_subname {
      font-size: 12px;
      font-family: map-get($theme, fontFamily);
      color: map-get($theme, text);
      margin-bottom: 25px;
    }
    .pick_drop_heading {
      &.pickup_top {
        margin-top: 20px;
      }
      .head {
        font-size: 14px;
        font-family: map-get($theme, fontFamily);
        color: map-get($theme, text);
        font-weight: bold;
        margin-bottom: 5px;
      }
      .subhead {
        font-size: 14px;
        font-family: map-get($theme, fontFamily);
        color: map-get($theme, text);
        font-weight: 400;
        margin-bottom: 0px;
      }
    }
  }
  .center_btn {
    .common_btn {
      width: auto;
      max-width: max-content;
      font-size: 16px;
      font-weight: 500;
      padding: 20px 40px;
    }
  }
}
