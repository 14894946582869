.faq_section {
  margin-top: 50px;
  margin-bottom: 100px;
  .faq_inner {
    margin-bottom: 15px;
    p {
      margin-top: 10px;
    }
    .career_panel {
      .accordion {
        background-color: map-get($theme, link);
        color: #444;
        cursor: pointer;
        padding: 18px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-size: 15px;
        transition: 0.4s;
        font-family: map-get($theme, fontFamily);
      }
      .active,
      .accordion:hover {
        background-color: map-get($theme, link);
      }

      .accordion:after {
        content: "\002B";
        color: #777;
        font-weight: bold;
        float: right;
        margin-left: 5px;
      }

      .active:after {
        content: "\2212";
      }

      .panel {
        padding: 0 8px;
        background-color: map-get($theme, bg);
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        border: 2px solid map-get($theme, link);
        border-radius: 0px 0px 10px 10px;
        font-family: map-get($theme, fontFamily);
      }
    }
  }
}
