body.zenMode {
  header.header {
    &:not(.menuOpened) {
      transform: translateY(-100.1%);
      // .mainNav {
      //   &.navbar-expand-lg {
      //     .navbar-nav {
      //       .dropdown-menu {
      //         &.megaMenu {
      //           height: 100vh;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
  // .ISI {
  //   transform: translateY(150%);
  // }
}
