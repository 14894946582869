.card {
  &Header {
  }
  &Footer {
  }
  &Content {
  }
  &.car_card {
    margin: 15px;
    padding: 25px;
    text-align: center;
    border-radius: 25px;
    @extend %animateFast;
    --app-card-height: 420px;
    background: map-get($theme, card);
    border: 2px solid map-get($theme, card);
    height: 420px;
    // max-height: var(--app-card-height, 420px);
    .car_head {
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
      text-align: center;
      margin-bottom: 10px;
      font-family: map-get($theme, fontFamily);
      a {
        color: map-get($theme, text);
      }
    }
    .car_subhead {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 35px;
      text-align: center;
      font-family: map-get($theme, fontFamily);
    }
    .car_img {
      width: 227px;
      margin: auto;
      height: 117px;
      margin-bottom: 50px;
    }
    picture {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .car_details {
      &.car_details_top {
        margin-top: 35px;
      }
      i {
        font-size: 30px;
        color: map-get($theme, cartext);
        @media (min-width: 1200px) and (max-width: 1400px) {
          font-size: 25px;
        }
      }
      p {
        color: map-get($theme, textL);
        font-size: 10px;
        font-weight: 400;

        font-family: map-get($theme, fontFamily);
        @media (min-width: 1200px) and (max-width: 1400px) {
          font-size: 7px;
        }
      }
      .red_button_cover{
        text-align: center;
        min-height: 60px;
        .red-button {
     
          display: inline-block;
          text-align: center;
          width: max-content;
          padding: 4px 12px;
          background-color: #ff0000;
          color: #ffffff;
          text-decoration: none;
          border-radius: 5px;
          font-size: 15px;
          font-weight: bold;
          border: none;
          margin:auto;
        }
        .regular_price{
          text-decoration: line-through;
          margin-top: 10px;
          font-size: 16px;
         }
      }
      
      .pricing {
        color: map-get($theme, cardborder);
        font-size: 26px;
        margin-top: 0px;
        font-weight: bold;
        font-family: map-get($theme, fontFamily);
        line-height: 30px;
        @include media-breakpoint-down(lg) {
          font-size: 20px;
          line-height: 0px;
        }
      }
      .subpricing {
        font-size: 16px;
        font-weight: 400;
        font-family: map-get($theme, fontFamily);
        color: map-get($theme, text);
        margin-top: 0;
      }
      button {
        width: 100%;
        max-width: 210px;
        padding: 14px;
        font-size: 22px;
        font-weight: bold;
        font-family: map-get($theme, fontFamily);
        margin: auto;
        text-align: center;
        justify-content: center;
        border-radius: 50px;
      }
      .ext {
        opacity: 0;
      }
    }
    &:hover {
      height: 100%;
      --app-card-height: 80vh;
      background: map-get($theme, bg);
      border: 3px solid map-get($theme, link);
      .car_details {
        .ext {
          opacity: 1;
        }
      }
    }
  }
  &._borderCard {
    border: 3px solid map-get($theme, brdr);
    border-radius: 16px;
    box-shadow: none;
    padding: 20px;

    &._sm {
      padding: 12px;
    }
    .title {
      font-size: 22px;
      font-family: map-get($theme, fontFamily);
      font-weight: 500;
      margin-bottom: 0px;
    }
    .car_subhead {
      font-size: 12px;
      font-family: map-get($theme, fontFamily);
      font-weight: 400;
      margin-top: 8px;
      text-align: center;
      margin-bottom: 30px;
    }
    .car_img {
      min-width: 227px;
      width: 100%;

      -o-object-fit: cover;
      height: 150px;
      object-fit: cover;
      @include media-breakpoint-down(lg) {
        height: auto;
      }
      picture{
        img{

    width: 100%;

        }
      }
    }
    .car_details {
      margin-top: 30px;
      justify-content: space-between;
      .car_icons {
        padding: 0px;
        text-align: center;
        min-width: 55px;
        i {
          font-size: 22px;
          color: map-get($theme, textL);
        }
        p {
          font-size: 10px;
          font-family: map-get($theme, fontFamily);
          font-weight: 400;
          color: map-get($theme, textL);
          word-break: break-word;
          line-height: 15px;
        }
        button {
          margin: 0;
          padding: 0;
          outline: none;
          border: 0px;
          &::after {
            display: none;
          }
        }
        &.car_icons2 {
          i {
            font-size: 8px;
          }
          p {
            margin-top: 4px;
          }
          ul {
            padding: 35px 15px 15px 5px;
            border-radius: 20px;
            .more_heading {
              font-size: 16px;
              font-family: map-get($theme, fontFamily);
              margin-bottom: 15px;
              text-align: initial;
              margin-left: 10px;
            }
            .more_details {
              display: flex;
              align-items: flex-start;
              margin-bottom: 10px;
              i {
                font-size: 22px;
                color: map-get($theme, textL);
                margin-right: 10px;
                margin-left: 10px;
                width: 22px;
                text-align: center;
              }
              p {
                margin: 0;
                color: map-get($theme, textL);
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .red_button_cover{
      text-align: center;
      min-height: 60px;
      .red-button {
   
        display: inline-block;
        text-align: center;
        width: max-content;
        padding: 4px 12px;
        background-color: #ff0000;
        color: #ffffff;
        text-decoration: none;
        border-radius: 5px;
        font-size: 15px;
        font-weight: bold;
        border: none;
        margin:auto;
      }
      .regular_price{
        text-decoration: line-through;
        margin-top: 10px;
        font-size: 16px;
       }
    }
    
   
    .h4 {
      font-family: map-get($theme, fontFamily);
      font-size: 24px;
      font-weight: 900;
      margin-top: 0px;
      color: map-get($theme, cardborder);
      margin-bottom: 0px;
      text-align: center;
     
    }
    .h5 {
      margin-top: 10px;
      font-size: 16px;
      font-family: map-get($theme, fontFamily);
      margin-bottom: 0px;

      font-weight: 400;
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
    .center_btn {
      margin-top: 25px;
      .common_btn {
        font-size: 18px;
      }
    }
    &:hover {
      border: 3px solid map-get($theme, link);
    }
  }
}
