$baseFontSize: 15px;

$fontFamily: var(--app-primary-font, "Roboto", sans-serif);
$scFontFamily: var(--app-secondary-font, "Open Sans", sans-serif);

$arFontFamily: "Cairo", sans-serif;

$icomoon-font-family: "icon";

// COMMON FONT CHART
// -----------------
$fontUL: 100; // 'fontName ultralight', sans-serif;
$fontT: 200; // 'fontName thin', sans-serif;
$fontL: 300; // 'fontName light', sans-serif;
$font: 400; // 'fontName regular', sans-serif;
$fontM: 500; // 'fontName medium', sans-serif;
$fontSB: 600; // 'fontName semibold', sans-serif;
$fontB: 700; // 'fontName bold', sans-serif;
$fontH: 800; // 'fontName heavy', sans-serif;
$fontBK: 900; // 'fontName black', sans-serif;

$global: (
  // GLOBAL vars
  device-switch: xl,
  border-radius: 0px,
  border-radius-sm: 0px,
  border-radius-lg: 3px,
  titleFont: $scFontFamily
);

$theme: (
  // THEME vars
  font: $font,
  fontL: $fontL,
  fontT: $fontT,
  fontM: $fontM,
  fontB: $fontB,
  fontH: $fontH,
  fontUL: $fontUL,
  fontSB: $fontSB,
  fontBK: $fontBK,
  fontFamily: $fontFamily,
  primary-font: $fontFamily,
  baseFontSize: $baseFontSize,
  scFontFamily: $scFontFamily,
  secondary-font: $scFontFamily,
  ar-primary-font: $arFontFamily,
  iconFont: $icomoon-font-family,
  bg: #fff,
  text: #000,
  write: #fff,
  accent: #333,
  link: #54dffe,
  textL: #545b6e,
  bgcolor: #f2f3f5,
  cartext: #535b6e,
  card: #f2f2f2,
  matBg: #edeef0,
  color: #54dffe,
  scroll: #54dffe,
  marker: #fff59f,
  selection: #0072ad,
  carselection: #094268,
  borderclr: #707070,
  cardborder: #00a0c4,
  brdr: rgba(#bbbec5, 0.237),
  header: (
    bg: #54dffe,
    link: #011b21,
    text: #011b21,
    active: (
      link: #011b21
    )
  ),
  footer: (
    text: #000,
    bg: #252525,
    link: #54dffe,
    bgcolor: #f2f3f5,
    whitecolor: #fff
  ),
  types: (
    dark: #000,
    light: #fff,
    info: #006ed0,
    warning: #fe6000,
    accent: #f16521,
    danger: #d9171e,
    primary: #54dffe,
    success: #078323,
    secondary: #f16521,
    hover: (
      bg: (
        dark: #000,
        light: #b9b9b9,
        accent: #efe1ff,
        secondary: #efe1ff,
        info: darken(#006ed0, 6.5%),
        warning: darken(#fe6000, 6.5%),
        danger: darken(#d9171e, 6.5%),
        success: darken(#078323, 6.5%),
        primary: darken(#54dffe, 6.5%)
      ),
      color: (
        info: #fff,
        dark: #fff,
        danger: #fff,
        warning: #fff,
        primary: #fff,
        success: #fff,
        light: #404041,
        accent: #654395,
        secondary: #654395
      )
    ),
    contrast: (
      info: #fff,
      dark: #fff,
      light: #000,
      accent: #fff,
      danger: #fff,
      warning: #fff,
      primary: #000,
      success: #fff,
      secondary: #fff
    )
  ),
  types_light: (
    light: #fff,
    dark: #b9b9b9,
    info: #c5dff7,
    accent: #f8f2ff,
    danger: #ffd2d4,
    primary: #fbf5ff,
    warning: #ffc39c,
    success: #cefff5,
    secondary: #f8f2ff,
    hover: (
      bg: (
        dark: #000,
        light: #fff,
        info: #006ed0,
        warning: #fe6000,
        danger: #d9171e,
        accent: #efe1ff,
        primary: #260750,
        success: #078323,
        secondary: #efe1ff
      ),
      color: (
        info: #fff,
        dark: #fff,
        light: #000,
        danger: #fff,
        warning: #fff,
        primary: #fff,
        success: #fff,
        accent: #654395,
        secondary: #654395
      )
    ),
    contrast: (
      dark: #000,
      info: #006ed0,
      warning: #fe6000,
      light: #424242,
      accent: #654395,
      danger: #d9171e,
      primary: #54dffe,
      success: #078323,
      secondary: #654395
    )
  )
);

$color: map-get($theme, types);
$cont: map-get($color, contrast);

@import "responsive";
