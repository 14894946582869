/**!
 *	Responsive Mixin
 *	@author: Ajith S Punalur (ASP)
 *  @date: 28-03-2021
 **/

@use "sass:math";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

@function em($px, $base: $baseFontSize) {
  @return math.div($px, $base) * 1em;
}
@function rem($px, $base: $baseFontSize) {
  @return math.div($px, $base) * 1rem;
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == "number" and not unitless($number) {
    @return math.div($number, $number * 0 + 1);
  }

  @return $number;
}

@function pxToVW($px, $base: $baseScreenWidth) {
  @return #{math.div($px, $base) * 100}vw;
}
@function pxToVH($px, $base: $baseScreenHeight) {
  @return #{math.div($px, $base) * 100}vh;
}

@function pxToVWCalc($px, $base: $baseWidth) {
  @return calc(100vw / #{math.div($base, $px)});
}
@function pxToVHCalc($px, $base: $baseScreenHeight) {
  @return calc(100vh / #{math.div($base, $px)});
}

/// Build Responsivenes with type casting
/// @param {Map} $opt - Map
/// @return {CssRule} - CSS Rules
@mixin buildResponsive($opt, $debug: false) {
  $opt: map-merge(
    (
      media: (),
    ),
    $opt
  );

  @if ($debug) {
    @debug #{"{"};
  }
  @each $key, $value in $opt {
    @if ($key != media) {
      @if (length($value) > 1) {
        // @debug nth($value, 2);
        @if (nth($value, 2) == "cast(px-vw)") {
          #{$key}: pxToVW(strip-unit(nth($value, 1)));
          @if ($debug) {
            @debug #{"  "}$key#{":"} pxToVW(strip-unit(nth($value, 1)));
          }
        } @else if (nth($value, 2) == "cast(px-vw_calc)") {
          #{$key}: pxToVW(strip-unit(nth($value, 1)));

          @if ($debug) {
            @debug #{"  "}$key#{":"} pxToVWCalc(nth($value, 1));
          }
        } @else if (nth($value, 2) == "cast(px-vh)") {
          #{$key}: pxToVH(strip-unit(nth($value, 1)));

          @if ($debug) {
            @debug #{"  "}$key#{":"} pxToVH(strip-unit(nth($value, 1)));
          }
        } @else if (nth($value, 2) == "cast(px-vh_calc)") {
          #{$key}: pxToVH(strip-unit(nth($value, 1)));

          @if ($debug) {
            @debug #{"  "}$key#{":"} pxToVHCalc(nth($value, 1));
          }
        }
      } @else {
        #{$key}: $value;

        @if ($debug) {
          @debug #{"  "}$key#{":"} $value;
        }
      }
    } @else if($key == media) {
      // @if($debug) {@if($debug) {@debug $key#{":"} $value;;}
      @each $k, $v in map-get($opt, media) {
        // @if($debug) {@if($debug) {@debug $k, $v;;}
        @if ($k==down) {
          @each $media, $props in $v {
            // @if ($debug) {
            //   @debug $media, $props;
            // }
            @include media-breakpoint-down($media) {
              @if ($debug) {
                @debug #{"  @MEDIA(" $media "-" $k ") {"};
              }

              @each $cssKey, $mediaVal in $props {
                #{$cssKey}: $mediaVal;
                @if ($debug) {
                  @debug #{"    "}$cssKey#{": "}$mediaVal#{";"};
                }
              }

              @if ($debug) {
                @debug #{"  }"};
              }
            }
          }
        }
      }
    }
  }

  @if ($debug) {
    @debug #{"}"};
  }
}
