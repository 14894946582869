.popup {
  &.popupVideo {
    background: transparent;
    .popHeader {
      height: 0;
      padding: 0;
      z-index: 2;
      min-height: 0;
      overflow: visible;
      position: absolute;
      border: 0 none transparent;
      .close {
        top: 3px;
        right: 3px;
        padding: 3px;
        line-height: 1;
        width: rem(36px);
        height: rem(36px);
        position: absolute;
        min-width: rem(36px);
        font-size: rem(24px);
        @media only screen and (min-width: 768px) {
          background: transparent;
        }
      }
      @media only screen and (max-width: 960px) and (max-height: 640px) {
        .close {
          right: 0;
          transform: translateY(100%);
          background: rgba(map-get($theme, color), 0.7);
        }
      }
    }
  }
}
