.about_banner {
  background-image: url("#{$img}/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .h3 {
    padding: 200px 0px 120px 0px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 1px;
  }
}
.about {
  margin-top: 100px;
  .content_sec {
    .h3 {
      text-align: left;
      font-size: 43px;
      margin-bottom: 40px;
      margin-top: 30px;
    }
    .content {
      padding-right: 20px;
      p {
        font-size: 18px;
        line-height: 30px;
        text-align: justify;
      }
    }
  }
  .img_sec {
    display: flex;
    align-items: center;
  }
}
.branches_sec {
  margin-top: 100px;
  margin-bottom: 100px;
  .branche_inner {
    margin-top: 50px;
    padding: 30px;
    .branchname {
      font-weight: bold;
      font-size: 30px;
      letter-spacing: 1px;
      color: #2d889d;
      margin-top: 15px;
    }
    .address {
      font-size: 18px;
      margin-bottom: 4px;
      margin-top: 21px;
    }
    .phone {
      margin-bottom: 0px;
    }
  }
}
.whoweare {
  padding: 100px 0px;
  background: #54dffe47;

  .content {
    line-height: 30px;
    margin-top: 30px;
  }
}
