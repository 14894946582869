.topFilter {
  padding-top: 100px;

  @include media-breakpoint-down(md) {
  }
  &.req_filter {
    padding-top: 0px;
  }
  h6,
  .h6 {
    @include font($fontM);
    padding-bottom: 5px;
  }

  i {
    font-size: 36px;
    @include media-breakpoint-down(md) {
      transform: rotate(90deg);
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
  .btn {
    @include media-breakpoint-down(md) {
      margin-top: 15px;
    }
  }
}

.iconWrap {
  color: map-get($theme, textL);
  p {
    font-size: 10px;
  }
}
.car_list_inner {
  display: flex;
  margin-top: 7px;
  justify-content: flex-start;
  @include media-breakpoint-down(lg) {
    flex-direction: row;
    flex-wrap: wrap;
    .car_type_sec {
      margin: 10px 5px;
    }
  }
  @include media-breakpoint-down(md) {
    justify-content: center;
  }
  .car_type_sec {
    position: relative;
    text-align: center;
    .form-check-input,
    & button {
      border: 1px solid #dbdadf;
      width: 90px;
      max-width: 90px;
      border-radius: 10px;
      font-size: 12px;
      text-align: center;
      padding: 5px 0px;
      margin: 0;
      margin-right: 0px;
      margin-right: 0px;
      margin-right: 7px;
      height: 50px;
      display: flex;
      justify-content: center;

      @include media-breakpoint-down(sm) {
        margin: auto;
      }
      &.form-check-input:checked[type="radio"],
      &.form-check-input:checked[type="checkbox"],
      &.form-check-input:checked {
        background: map-get($theme, carselection);
        & + label {
          color: map-get($theme, write);
          i {
            color: map-get($theme, write);
          }
        }
      }
      &:focus {
        box-shadow: none;
      }
      &::after {
        display: none;
      }
      i {
        font-size: 8px;
        text-align: center;
        color: map-get($theme, textL);
        margin-bottom: 0px;
      }
    }
    label {
      position: absolute;
      top: 12px;
      left: -8px;
      right: 0;
      bottom: 0;
      font-size: 10px;
      margin-bottom: 5px;
      line-height: 8px;
      color: map-get($theme, cartext);
      font-family: map-get($theme, fontFamily);
      i {
        font-size: 12px;
        text-align: center;
        color: map-get($theme, cartext);
        margin-bottom: 4px;
      }
      p {
      }
    }

    &.more_sec {
      display: flex;
      justify-content: center;
      align-items: center;
      label {
        top: 19px;
      }
      ul {
        padding: 35px 15px 15px 15px;
        border-radius: 20px;
        .more_heading {
          font-size: 16px;
          font-family: map-get($theme, fontFamily);
          margin-bottom: 15px;
        }
        .more_details {
          position: relative;
          display: flex;
          align-items: flex-start;
          margin-bottom: 10px;
          i {
            font-size: 22px;
            color: map-get($theme, textL);
            margin-right: 10px;
            width: 22px;
            text-align: center;
          }
          p {
            margin: 0;
            color: map-get($theme, textL);
            font-size: 12px;
          }
          input {
            width: 100%;
            max-width: 100%;
            margin-right: 0px;
          }
          label {
            top: 15px;
            left: 0;
            right: 0;
            text-align: center;
            i {
              font-size: 13px;
              color: map-get($theme, textL);
              margin-right: 10px;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.seat_list_inner {
  display: flex;
  margin-top: 7px;
  justify-content: flex-start;
  @include media-breakpoint-down(md) {
    justify-content: center;
  }
  .seat_type_sec {
    position: relative;
    text-align: center;
    .form-check-input {
      border: 1px solid #dbdadf;
      width: 69px;
      max-width: 69px;
      border-radius: 10px;
      font-size: 12px;
      text-align: center;
      padding: 5px 0px;
      margin: 0;
      margin-right: 0px;
      margin-right: 0px;
      margin-right: 7px;
      height: 50px;
      &.form-check-input:checked[type="radio"],
      &.form-check-input:checked[type="checkbox"],
      &.form-check-input:checked {
        background: map-get($theme, carselection);
        & + label {
          color: map-get($theme, write);
          i {
            color: map-get($theme, write);
          }
        }
      }
      &:focus {
        box-shadow: none;
      }
    }
    label {
      position: absolute;
      top: 6px;
      left: -8px;
      right: 0;
      bottom: 0;
      font-size: 16px;
      margin-bottom: 0px;
      line-height: 37px;

      color: map-get($theme, cartext);
      font-family: map-get($theme, fontFamily);
      i {
        font-size: 12px;
        text-align: center;
        color: map-get($theme, cartext);
        margin-bottom: 4px;
        font-weight: 500;
      }
      p {
      }
    }
    p {
      font-size: 16px;
      margin-bottom: 0px;
      line-height: 37px;
      color: map-get($theme, cartext);
      font-family: map-get($theme, fontFamily);
      font-weight: 500;
    }
  }
}
._borderCard {
}
