.banner {
  overflow: hidden;
  @include media-breakpoint-down(lg) {
    margin-top: 73px;
  }
  &.inner {
  }
  picture {
    width: 100%;
    img {
      width: 100%;
      @include media-breakpoint-down(md) {
        height: 31vh !important;
        object-fit: cover;
      }
    }
  }
  .banner_inner {
    padding-left: 16%;
    padding-right: 16%;
    position: absolute;
    top: 22%;

    .heading1 {
      font-size: 22px;
      color: white;
      line-height: 26px;
      font-family: map-get($theme, fontFamily);
    }
    .heading2 {
      font-size: 66px;
      font-weight: bold;
      color: white;
      line-height: 66px;
      font-family: map-get($theme, fontFamily);
    }
    @include media-breakpoint-down(lg) {
      padding-left: 2%;
      top: 25px;
      .heading1 {
        font-size: 16px;
      }
      .heading2 {
        font-size: 27px;
        line-height: 33px;
      }
    }
  }
}
