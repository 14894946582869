$footer: map-get($theme, footer);

footer.footer {
  padding: 130px 0px 180px 0px;
  // font-size: rem(14px);
  // line-height: em(18px, 14px);
  color: map-get($footer, text);
  background: map-get($footer, bgcolor);
  @include media-breakpoint-down(md) {
    padding: 5px 0px 50px 0px;
  }
  a {
    color: map-get($footer, text);
  }
  .footer_head {
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 28px;
    font-weight: 400;
    text-transform: capitalize;
    @include media-breakpoint-down(lg) {
      margin-top: 30px;
    }
  }
  .about_content {
    font-family: map-get($theme, fontFamily);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .unumber {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 0px;
  }
  .emaillink {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    margin-bottom: 0px;
  }
  .info_link {
    margin-bottom: 12px;
    a {
      color: map-get($footer, text);
      font-size: 14px;
      font-family: map-get($theme, fontFamily);
    }
  }
  .social_icons {
    i {
      width: 41px;
      height: 41px;
      font-size: 12px;
      background: map-get($footer, whitecolor);
      text-align: center;
      line-height: 41px;
      border-radius: 100%;
      margin-right: 25px;
      margin-bottom: 10px;
      box-shadow: 0px 1px 5px 0px #00000078;
    }
  }
  .terms {
    margin-top: 15px;
    a {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      margin-bottom: 0px;
    }
  }
  .item_tag {
    p {
      padding: 20px 15px;
      background: map-get($footer, whitecolor);
      text-align: center;
      border-radius: 10px;
      width: max-content;
      margin-right: 8%;
      cursor: pointer;
    }
  }
}
